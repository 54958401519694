import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {UserType} from '../../model/user/user-type';

@Injectable({providedIn: 'root'})
export class AuthenticatedRouteGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    const principal = this.authService.getPrincipal();
    if (isAuthenticated && principal.type === UserType.USER) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
