import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HttpDomainInterceptor} from './core/interceptors/http-domain-interceptor.service';
import {HttpErrorInterceptorService} from './core/interceptors/http-error-interceptor.service';
import {HttpTokenInterceptor} from './core/interceptors/http-token-interceptor.service';
import {DialogComponent} from './components/dialog/dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DashboardPageComponent} from './pages/dashboard/dashboard-page.component';
import {PaymentPageComponent} from './pages/payment/payment-page.component';
import {ActivationPageComponent} from './pages/register/invitation/activation-page.component';
import {RegisterComponent} from './pages/register/register.component';
import {SuperadminComponent} from './pages/admin/superadmin.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {DialogAddUserComponent} from './pages/admin/dialog-add-user/dialog-add-user.component';
import {LoginComponent} from './pages/login/login.component';
import {DialogEditUserComponent} from './pages/admin/dialog-edit-user/dialog-edit-user.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import {QRCodeModule} from 'angularx-qrcode';
import {QrCodeModule} from 'ng-qrcode';
import {DevicesComponent} from './pages/admin/devices/devices.component';
import {MessagesPageComponent} from './pages/admin/messages/messages-page.component';
import {FooterComponent} from './components/footer/footer.component';
import {DialogMessagesComponent} from './pages/admin/messages/dialog-messages/dialog-messages.component';
import {SurveysComponent} from './pages/admin/surveys/surveys.component';
import {DialogCreateSurveyComponent} from './pages/admin/surveys/dialog-create-survey/dialog-create-survey.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DialogEditSurveyComponent} from './pages/admin/surveys/dialog-edit-survey/dialog-edit-survey.component';
import {MatSelectModule} from '@angular/material/select';
import {DatePipe} from './core/pipes/date.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {InvalidSubscriptionComponent} from './pages/dashboard/subscription/invalid-subscription/invalid-subscription.component';
import {ForgotPasswordConfirmationComponent} from './pages/forgot-password/forgot-password-confirm.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SuperadminComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    DialogAddUserComponent,
    DialogEditUserComponent,
    DialogComponent,
    MessagesPageComponent,
    DashboardPageComponent,
    PaymentPageComponent,
    ActivationPageComponent,
    DevicesComponent,
    FooterComponent,
    DialogMessagesComponent,
    SurveysComponent,
    DialogCreateSurveyComponent,
    DialogEditSurveyComponent,
    DatePipe,
    ForgotPasswordConfirmationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    QrCodeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatProgressBarModule,
    MatSnackBarModule,
    NgxStripeModule.forRoot(environment.stripeApiKey),
    MatCheckboxModule,
    MatTooltipModule,
    MatSidenavModule,
    ClipboardModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpDomainInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true},
  ],
  exports: [
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
