<div class="auth-container">
  <div class="left-section-auth">
    <div class="background-logo">
      <div class="logo-container">
        <img src="../../../assets/logos/logo-600w.png" class="logo" alt="Answersure Logo"/>
      </div>
    </div>
      <div class="auth-form">
        <form [formGroup]="loginForm" (ngSubmit)="submit()" autocomplete="off">
          <mat-form-field class="input-fields" appearance="outline">
            <mat-label>Email</mat-label>
            <input type="text" matInput placeholder="Email" formControlName="email" name="email" autocomplete="off"/>
            <mat-error *ngIf="loginForm.invalid">This field is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input #passwordInput type="password" matInput placeholder="Password" formControlName="password" name="pass" autocomplete="off"/>
            <button mat-icon-button matSuffix class="password-eye-icon" type="button" (click)="passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password'" [attr.aria-label]="'Hide password'">
              <mat-icon>visibility</mat-icon>
            </button>
            <mat-error *ngIf="loginForm.invalid">This field is required</mat-error>
          </mat-form-field>

          <div>
            <button mat-button class="btn-custom">Login</button>

            <div class="redirect-link flex-space-between">
              <span routerLink="/register">Create an account</span>
              <span routerLink="/forgot-password">Forgot Password?</span>
            </div>
          </div>
        </form>
      </div>
  </div>
  <div class="right-section-auth"></div>
</div>
