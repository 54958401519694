import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginResponse} from '../../model/login/login-response';
import {Observable, Subject} from 'rxjs';
import {LoginRequest} from '../../model/login/login-request';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {tap} from 'rxjs/operators';
import {User} from '../../model/user/user';
import {UserType} from '../../model/user/user-type';
import {SubscriptionStatus} from '../../model/subscription/subscription-status';

@Injectable({providedIn: 'root'})
export class AuthService {

  session: LoginResponse;
  private SESSION_STORAGE_KEY = 'session';
  private logoutBehaviourSubject = new Subject<any>();
  private loginBehaviourSubject = new Subject<any>();
  private userChange = new Subject<User>();

  constructor(
    private readonly http: HttpClient,
    private router: Router,
    private modalService: MatDialog
  ) {
    const existingSession: LoginResponse = JSON.parse(localStorage.getItem(this.SESSION_STORAGE_KEY));
    if (existingSession) {
      this.session = existingSession;
    }
  }

  getLogoutSubjectAsObservable(): Observable<any> {
    return this.logoutBehaviourSubject.asObservable();
  }

  getLoginSubjectAsObservable(): Observable<any> {
    return this.loginBehaviourSubject.asObservable();
  }

  login(loginRequest: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `/login`, loginRequest
    ).pipe(
      tap(response => {
        this.authenticate(response);
      })
    );
  }

  logout(): void {
    if (this.session) {
      this.http.put('/logout', null).subscribe(success => {
        this.cleanUpSession();
        this.router.navigate(['/login']);
        this.modalService.closeAll();
      }, error => {
        this.cleanUpSession();
        this.router.navigate(['/login']);
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  cleanUpSession(): void {
    this.session = null;
    localStorage.removeItem(this.SESSION_STORAGE_KEY);
    this.logoutBehaviourSubject.next(true);
    this.userChange.next(null);
  }

  updatePrincipal(principal: User): void {
    this.session.user = principal;
    this.userChange.next(principal);
    localStorage.setItem(this.SESSION_STORAGE_KEY, JSON.stringify(this.session));

  }

  isAuthenticated(): boolean {
    return this.session && true;
  }

  getPrincipal(): User {
    if (this.session) {
      return this.session.user;
    } else {
      return null;
    }
  }

  onPrincipalChange(): Observable<User> {
    return this.userChange.asObservable();
  }

  authenticate(response: LoginResponse): LoginResponse {
    this.session = response;
    try {
      localStorage.setItem(this.SESSION_STORAGE_KEY, JSON.stringify(response));
    } catch (e) {
      console.error(e);
    }
    this.loginBehaviourSubject.next(true);
    this.userChange.next(response.user);

    return response;
  }

  getHomePageForUser(): string {
    const principal = this.getPrincipal();
    let redirectUrl = '/login';
    if (principal) {
      if (principal.type === UserType.SUPER_ADMIN) {
        redirectUrl = '/admin';
      } else { // he is normal user
        if (this.hasValidSubscription()) {
          redirectUrl = '/dashboard/devices';
        } else {
          redirectUrl = '/dashboard/subscription'; // the only page he can access
        }

      }
    }
    console.log(redirectUrl);
    return redirectUrl;
  }

  getToken(): string | null {
    if (this.session) {
      return this.session.token;
    } else {
      return null;
    }
  }

  hasValidSubscription(): boolean {
    const principal = this.getPrincipal();
    if (!principal) {
      return false;
    }
    const status = principal.subscriptionStatus;
    return status === SubscriptionStatus.ACTIVE
      || status === SubscriptionStatus.CANCELED
      || status === SubscriptionStatus.TRIALING;
  }

}
