<div class="wrapper">
  <app-header></app-header>
  <div class="table-container page-width">
    <div class="flex top-table-container">
      <div class="filter-input">
        <mat-form-field>
          <mat-label>Search device</mat-label>
          <input matInput (keyup)="applyFilter(searchInput.value)" placeholder="" #searchInput name="search-device" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <table mat-table [dataSource]="devicesTable">
      <ng-container matColumnDef="deviceType">
        <th mat-header-cell *matHeaderCellDef class="text-centered">OS</th>
        <td mat-cell *matCellDef="let device" class="text-centered">
          <span *ngIf="device.isAndroid" class="device-icon android"><i class="fab fa-android"></i></span>
          <span *ngIf="!device.isAndroid" class="device-icon apple"><i class="fab fa-apple"></i></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let device" class="content-ellipsis" matTooltip="{{device.id}}" matTooltipPosition="above" matTooltipClass="tooltip-class">{{device.id}}</td>
      </ng-container>
      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Active</th>
        <td mat-cell *matCellDef="let device" class="text-centered"><i
          [ngClass]="device.isActive ? 'fa-check' : 'fa-times'" class="fas dark-blue-color"></i></td>
      </ng-container>
      <ng-container matColumnDef="pushId">
        <th mat-header-cell *matHeaderCellDef>PushID</th>
        <td mat-cell *matCellDef="let device" class="content-ellipsis" matTooltip="{{device.pushId}}" matTooltipPosition="above" matTooltipClass="tooltip-class">{{device.pushId }}</td>
      </ng-container>
      <ng-container matColumnDef="activatedBy">
        <th mat-header-cell *matHeaderCellDef>Activated By</th>
        <td mat-cell *matCellDef="let device" class="content-ellipsis" matTooltip="{{device.activatedByUserEmail}}" matTooltipPosition="above" matTooltipClass="tooltip-class"> {{device.activatedByUserEmail || '-'}}</td>
      </ng-container>
      <ng-container matColumnDef="owned">
        <th mat-header-cell *matHeaderCellDef class="text-centered">QR Confirmed</th>
        <td mat-cell *matCellDef="let device" class="text-centered"><i
          [ngClass]="device.owned ? 'fa-check' : 'fa-times'" class="fas dark-blue-color"></i></td>
      </ng-container>
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>Label</th>
        <td mat-cell *matCellDef="let device" class="content-ellipsis" matTooltip="{{device.label}}" matTooltipPosition="above" matTooltipClass="tooltip-class">{{device.label || '-'}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th class="text-centered" mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="text-centered" mat-cell *matCellDef="let device">
          <span class="actions-icons" (click)="deleteDevice(device.id)"><i class="fa fa-trash-alt actions-icon"
                                                                           title="Delete"></i></span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<app-footer></app-footer>
