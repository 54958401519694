import {environment} from '../environments/environment';

export class Constants {
  public static readonly BASE_DOMAIN = environment.baseDomain;
  public static readonly BUSINESS_ERROR_CODE = 503;
  public static readonly PERMISSION_DENIED_ERROR_CODE = 403;
  public static readonly AUTH_ERROR_CODE = 401;

  public static readonly DEFAULT_PAGE_SIZE = 50;
  public static readonly PASSWORD_MIN_LENGTH = 5;
  public static readonly PASSWORD_MAX_LENGTH = 50;

  public static readonly EMAIL_REGEX = /\S+@\S+\.\S+/;
  // public static readonly DATE_REGEX = /^(\d{2}|\d{1})\/(\d{2}|\d{1})\/\d{4}$/;
  public static readonly DATE_REGEX = new RegExp(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);

  public static readonly MIN_SUBSCRIPTION_AMOUNT = 10;

  public static readonly PER_CREDIT_PRICE = 10;


}

