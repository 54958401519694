import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogAddUserComponent} from './dialog-add-user/dialog-add-user.component';
import {DialogEditUserComponent} from './dialog-edit-user/dialog-edit-user.component';
import {UserService} from '../../core/services/user.service';
import {User} from '../../model/user/user';
import {DialogService} from '../../components/dialog/dialog.service';
import {NotifService} from '../../core/services/notif.service';

@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.scss']
})

export class SuperadminComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'status', 'subscriptionStatus', 'subscriptionStartDate', 'subscriptionEndDate', 'totalCredits', 'availableCredits', 'actions'];
  dataSource = new MatTableDataSource([]);

  constructor(public dialog: MatDialog, private readonly userService: UserService, private dialogService: DialogService, private notifService: NotifService) {
  }

  ngOnInit(): void {
    this.userService.getAllUsers().subscribe(users => {
      this.dataSource = new MatTableDataSource<User>(users);
    });
  }

  applyFilter(inputValue: string): void {
    this.dataSource.filter = inputValue.trim().toLowerCase();
  }

  openDialogAdd(): void {
    this.dialog.open(DialogAddUserComponent, {
      panelClass: 'width-user-dialog',
      autoFocus: false,
    });
  }

  openDialogEdit(user: User): void {
    this.dialog.open(DialogEditUserComponent, {
      data: user,
      panelClass: 'width-user-dialog',
      autoFocus: false,
    });
  }

  deleteUser(user: User): void {
    this.dialogService.openConfirmDialog('Delete user', `Are you sure you want to delete ${user.email}?`, () => {
      this.userService.deleteUser(user.id).subscribe(succces => {
          this.notifService.success('User has been deleted');
          this.dialog.closeAll();
          this.ngOnInit();
        },
        error => {
          this.notifService.error(error.error.message);
          this.ngOnInit();
        }
      );
    });
  }

}
