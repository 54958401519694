import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../constants';
import {RegistrationService} from '../../core/services/registration.service';
import {RegistrationRequest} from '../../model/registration-request';
import {NotifService} from '../../core/services/notif.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registrationSuccess = false;

  constructor(private fb: FormBuilder, private registrationService: RegistrationService,
              private notifService: NotifService) {
  }

  registrationForm: FormGroup = this.fb.group(
    {
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      email: new FormControl('', [Validators.required, Validators.pattern(Constants.EMAIL_REGEX), Validators.maxLength(100)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    }
  );

  ngOnInit(): void {
  }

  submitRegisterForm(): void {
    console.log('submitCreateForm register form');
    if (this.registrationForm.valid) {
      const name = this.registrationForm.get('name').value;
      const emailValue = this.registrationForm.get('email').value;
      const passwordValue = this.registrationForm.get('password').value;
      const request: RegistrationRequest = {name: name, email: emailValue, password: passwordValue};
      this.registrationService.register(request).subscribe(success => {
        this.registrationSuccess = true;
      }, error => {
        this.notifService.error(error.error.message);
        console.log(error);
      });
    }
  }

}
