<div class="position-relative container-details">
  <div class="main-containers details-device">
    <div routerLink="/dashboard/devices" class="font-15 text-color back-to-container">
      <div class="icon-back">
        <i class="fas fa-arrow-left"></i>
      </div>
      <div class="back-to bold-text">Back to List</div>
    </div>

    <div *ngIf="device">
      <div class="flex-column">
        <div class="name">
          <div class="bold-text font-15 text-color">Name: </div>
          <div *ngIf="!device.isInEditMode" class="flex">
            <div class="font-15">{{device.label}}</div>
          </div>
          <div *ngIf="device.isInEditMode" class="input-edit">
            <input [(ngModel)]="device.newLabel" type="text" name="name-label" autocomplete="off"
                   class="base-input">
          </div>
        </div>
        <div class="bold-text font-15 text-color" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Id: {{device.id}}</div>
        <div class="align-flex-end icons">
      <span *ngIf="!device.isInEditMode">
             <span (click)="device.isInEditMode = true;" class="text-color margin-right-icon"><i class="far fa-edit"></i></span>
             <span (click)="deleteDevice(device.id)" class="text-color"><i class="far fa-trash-alt"></i></span>
      </span>
          <span *ngIf="device.isInEditMode" class="flex-full-centered actions">
        <span (click)="confirmEditName(device)" class="font-17 text-color margin-right-icon"><i class="far fa-check-circle"></i></span>
        <span (click)="device.isInEditMode = false; device.newLabel = device.label;"
              class="font-17 text-color"><i class="far fa-times-circle"></i></span>
      </span>
        </div>
      </div>
    </div>
  </div>
</div>
