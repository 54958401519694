import {Component, HostListener} from '@angular/core';
import {AuthService} from './core/services/auth.service';
import {LoaderService} from './core/services/loader.service';
import {SubscriptionService} from './core/services/subscription.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None
})

export class AppComponent {
  title = 'AnswerSure';
  displayLoadingBar = false;

  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      this.displayLoadingBar = v;
    });

  }

}
