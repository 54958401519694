<header class="header-bar">
  <div class="header-content" [ngClass]="isUserDashboard ? 'page-width-dashboard' : 'page-width'">
    <div class="logo-space-up">
      <img src="../../../assets/logos/logo-full-h50.png"
           class="answersure-logo-header" alt="Answersure Logo"/>
    </div>
    <div [ngClass]="isUserDashboard ? 'display-menu' : ''">
      <button mat-button [disableRipple]="true" [matMenuTriggerFor]="menu" class="btn-mat-menu">
        <div class=" right-section-header flex-space-between">
          <div class="user-image"></div>
          <div class="user-name-text">{{principal.name || principal.email}}</div>
          <i class="fas fa-bars"></i>
        </div>
      </button>
      <div class="menu">
        <mat-menu #menu="matMenu" xPosition="before">
          <button *ngIf="isAdmin" mat-menu-item routerLink="/admin">
            <span class="menu-icon"><i class="fas fa-users"></i></span>
            <span class="text-menu-item">Users</span>
          </button>
          <button *ngIf="isAdmin" mat-menu-item routerLink="/admin/devices">
            <span class="menu-icon"><i class="fas fa-mobile-alt"></i></span>
            <span class="text-menu-item">Devices</span>
          </button>
          <button *ngIf="isAdmin" mat-menu-item routerLink="/admin/messages">
            <span class="menu-icon"><i class="fas fa-share-square"></i></span>
            <span class="text-menu-item">Messages</span>
          </button>
          <button *ngIf="isAdmin" mat-menu-item routerLink="/admin/surveys">
            <span class="menu-icon"><i class="fas fa-question"></i></span>
            <span class="text-menu-item">Surveys</span>
          </button>
          <div class="mobile-menu-view">
            <button mat-menu-item *ngIf="!isAdmin && principal.subscriptionId" [class.disabled-item]="hasInvalidSubscription" routerLink="/dashboard/devices">
              <span class="menu-icon"><i class="fas fa-list"></i></span>
              <span class="text-menu-item">Devices</span>
            </button>
            <button mat-menu-item *ngIf="!isAdmin && principal.subscriptionId" [class.disabled-item]="hasInvalidSubscription" routerLink="/dashboard/scan-to-activate">
              <span class="menu-icon"><i class="fas fa-qrcode"></i></span>
              <span class="text-menu-item">Scan To Activate</span>
            </button>
            <button mat-menu-item *ngIf="!isAdmin && principal.subscriptionId" routerLink="/dashboard/subscription" >
              <span class="menu-icon"><i class="far fa-bookmark"></i></span>
              <span class="text-menu-item">View Subscription</span>
            </button>
            <button mat-menu-item *ngIf="!isAdmin && principal.subscriptionId"  routerLink="/dashboard/change-password">
              <span class="menu-icon"><i class="fas fa-lock"></i></span>
              <span class="text-menu-item">Change Password</span>
            </button>
          </div>
          <button mat-menu-item (click)="logout()">
            <span class="menu-icon"><i class="fas fa-sign-out-alt"></i></span>
            <span class="text-menu-item">Log Out</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</header>
