import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NotifService} from '../../core/services/notif.service';
import {AuthService} from '../../core/services/auth.service';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {ConfirmCardSetupData, StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {PaymentStripeRequest} from '../../model/subscription/payment-stripe-request';
import {SubscriptionService} from '../../core/services/subscription.service';
import {environment} from '../../../environments/environment';
import {User} from '../../model/user/user';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})

export class PaymentPageComponent implements OnInit {

  readonly PER_CREDIT_PRICE = environment.perCreditPrice;
  readonly trialPeriod = environment.trialPeriod;

  @ViewChild(StripeCardComponent, {static: true}) card: StripeCardComponent;

  isLoading = false;
  principal: User;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    classes: {base: 'stripe-input'},
    style: {
      base: {
        iconColor: '#0F4B9F',
        color: '#0A336B',
        fontWeight: '300',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '13px',
        '::placeholder': {
          color: '#83B7DC',
          fontSize: '13.3333px',
        }
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  paymentForm: FormGroup;

  constructor(private readonly authService: AuthService, private router: Router, private notifService: NotifService, private fb: FormBuilder, private stripeService: StripeService,
              private readonly http: HttpClient, private subscriptionService: SubscriptionService) {

  }


  ngOnInit(): void {
    if (this.authService.hasValidSubscription()) {
      this.router.navigate(['dashboard']);
      return;
    }
    this.principal = this.authService.getPrincipal();
    this.paymentForm = this.fb.group({
      name: [undefined, [Validators.required]],
      phoneNumber: [undefined, [Validators.required]],
      credits: [1, [Validators.required, Validators.pattern(/\d+/), Validators.min(1)]],
      address: this.fb.group({
        line1: [undefined, [Validators.required, Validators.maxLength(100)]],
        line2: [undefined, [Validators.maxLength(100)]],
        country: [undefined, [Validators.required, Validators.maxLength(100)]],
        city: [undefined, [Validators.maxLength(100)]],
        state: [undefined, [Validators.required, Validators.maxLength(100)]],
        postalCode: [undefined, [Validators.maxLength(100)]],
      }),
    });
  }

  submitPayment(): void {
    const name = this.paymentForm.get('name').value;
    const request: PaymentStripeRequest = {
      ...this.paymentForm.value,
    };

    this.paymentForm.markAllAsTouched();

    if (!this.paymentForm.valid) {
      this.notifService.error('Your form is invalid');
      return;
    }
    this.stripeService.createToken(this.card.element, {name}) // this method reference the card data
      .subscribe(cardToken => {
        if (cardToken.error) {
          console.log('error');
          this.notifService.error(cardToken.error.message);
          return;
        }
        // let cardData: Omit<CreatePaymentMethodCardData, 'type'> = {card: this.card.element};
        const cardData: ConfirmCardSetupData = {payment_method: {card: this.card.element}}; // it seems the ngx library is old
        this.isLoading = true;
        this.subscriptionService.createSetupIntent().subscribe(setupIntentSecret => {
          this.stripeService.confirmCardSetup(setupIntentSecret.value, cardData).subscribe(result => {
            console.log(result);
            if (result.error) {
              this.notifService.error(result.error.message);
              this.isLoading = false;
              return;
            }
            request.stripeToken = result.setupIntent.id;
            request.sourceToken = cardToken.token.id;
            this.subscriptionService.startSubscription(request).subscribe(updatedUser => {
              this.isLoading = false;
              this.authService.updatePrincipal(updatedUser);
              this.router.navigate([this.authService.getHomePageForUser()]);
              this.notifService.success('Your subscription was successfully started');
            }, error => {
              this.isLoading = false;
              this.notifService.error(error.error.message);
            });
          }, error => {
            this.isLoading = false;
            this.notifService.error('Something went wrong.. Please come back later :(');
          });
        }, error => {
          this.isLoading = false;
          this.notifService.error('Something went wrong.. Please come back later :(');
        });
      }, error => {
        this.isLoading = false;
        this.notifService.error('Something went wrong.. Please come back later :(');
      });
  }

  get Math(): any {
    return Math;
  }

}
