<div class="center-content">
  <h2 mat-dialog-title class="title">Add User</h2>
</div>
<mat-dialog-content>
  <div class="center-content">
    <form [formGroup]="addUserForm" (ngSubmit)="submitAddUser()" class="flex-column">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input type="text" matInput placeholder="Name" formControlName="name"/>
        <mat-error *ngIf="addUserForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input type="text" matInput placeholder="Email" formControlName="email"/>
        <mat-error *ngIf="addUserForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Subscription Start Date</mat-label>
        <input matInput [matDatepicker]="pickDateStart" placeholder="Choose a date"
               formControlName="subscriptionStartDate">
        <mat-datepicker-toggle [for]="pickDateStart" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #pickDateStart></mat-datepicker>
        <mat-error *ngIf="addUserForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Subscription End Date</mat-label>
        <input matInput [matDatepicker]="pickDateEnd" placeholder="Choose a date" formControlName="subscriptionEndDate">
        <mat-datepicker-toggle [for]="pickDateEnd" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #pickDateEnd></mat-datepicker>
        <mat-error *ngIf="addUserForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Total Credits</mat-label>
        <input type="number" matInput placeholder="Total Credits" formControlName="totalCredits"/>
        <mat-error *ngIf="addUserForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Available Credits</mat-label>
        <input type="number" matInput placeholder="Available Credits" formControlName="availableCredits"/>
        <mat-error *ngIf="addUserForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <div class="actions margin-bottom-15">
        <mat-dialog-actions align="end">
          <div class="action-btn-cancel">
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
          </div>
          <div class="action-btn">
            <button mat-button type="submit">Save</button>
          </div>
        </mat-dialog-actions>
      </div>
    </form>
  </div>
</mat-dialog-content>

