import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DashboardDevicesComponent} from '../devices/dashboard-devices.component';
import {ScanToActivateComponent} from '../scan-to-activate/scan-to-activate.component';
import {SubscriptionComponent} from '../subscription/subscription.component';
import {ChangePasswordComponent} from '../change-password/change-password.component';
import {ViewDetailsComponent} from '../view-details/view-details.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {QrCodeModule} from 'ng-qrcode';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {AppModule} from '../../../app.module';
import {InvalidSubscriptionComponent} from '../subscription/invalid-subscription/invalid-subscription.component';


@NgModule({
  declarations: [
    DashboardDevicesComponent,
    ScanToActivateComponent,
    SubscriptionComponent,
    ChangePasswordComponent,
    ViewDetailsComponent,
    InvalidSubscriptionComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    QrCodeModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule,
    ClipboardModule,
    MatIconModule,
  ]
})
export class DashboardModule {
}
