import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserType} from '../../model/user/user-type';
import {AuthService} from '../services/auth.service';

@Injectable({providedIn: 'root'})
export class AdminRouteGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(): boolean {
        let principal = this.authService.getPrincipal();
        if (!this.authService.isAuthenticated() || principal.type !== UserType.SUPER_ADMIN) {
            this.router.navigate(['login']);
            return false;
        } else {
            return true;
        }
    }
}
