import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MobileDevice} from '../../../model/device/mobile-device';
import {MobileService} from '../../../core/services/mobile.service';
import {NotifService} from '../../../core/services/notif.service';
import {DialogService} from '../../../components/dialog/dialog.service';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})

export class DevicesComponent implements OnInit {
  devicesTable = new MatTableDataSource([]);
  displayedColumns: string[] = ['deviceType', 'id', 'isActive', 'pushId', 'label', 'activatedBy', 'owned', 'actions'];

  constructor(private readonly mobileService: MobileService,
              private readonly notifService: NotifService,
              private readonly dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.mobileService.getAllMobileDevices().subscribe(devices => this.devicesTable = new MatTableDataSource<MobileDevice>(devices)
    );
  }

  applyFilter(inputValue: string): void {
    this.devicesTable.filter = inputValue.trim().toLowerCase();
  }

  deleteRemoveDevice(deviceId: string): void {
    this.dialogService.openConfirmDialog('Delete Device', 'Are you sure you want to delete the specified deviceDetails?', () => {
      this.mobileService.deleteDevice(deviceId).subscribe(success => {
        this.ngOnInit();
      }, error => {
        this.notifService.error(error.error.message);
      });
    });
  }

  deleteDevice(deviceId: string): void {
    this.dialogService.openConfirmDialog('Delete Device', 'Are you sure you want to delete the specified deviceDetails?', () => {
      this.mobileService.deleteDevice(deviceId).subscribe(success => {
        this.ngOnInit();
      }, error => {
        this.notifService.error(error.error.message);
      });
    });
  }
}
