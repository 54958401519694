import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {User} from '../../../model/user/user';
import {MobileService} from '../../../core/services/mobile.service';
import {MobileDeviceListItem} from '../../../model/device/mobile-device-list-item';
import {NotifService} from '../../../core/services/notif.service';
import {DialogService} from '../../../components/dialog/dialog.service';
import {SubscriptionService} from '../../../core/services/subscription.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard-devices',
  templateUrl: './dashboard-devices.component.html',
  styleUrls: ['./dashboard-devices.component.scss']
})
export class DashboardDevicesComponent implements OnInit {

  principal: User;
  devices: MobileDeviceListItem[];
  newRemoteDeviceId: string;
  newLabel: string;

  constructor(private authService: AuthService, private mobileService: MobileService,
              private router: Router,
              private notifService: NotifService, private dialogService: DialogService,
              private subscriptionService: SubscriptionService) {

  }

  ngOnInit(): void {
    this.principal = this.authService.getPrincipal();
    this.mobileService.getMobileDevicesForUser().subscribe(devices => {
      // tslint:disable-next-line:label-position
      this.devices = devices.map(device => {
        return {...device, isInEditMode: false, newLabel: device.label};
      });
    });
    this.fetchSubscriptionInfo();
  }

  fetchSubscriptionInfo(): void {
    this.subscriptionService.getSubscription().subscribe(subscriptionInfo => {
      this.principal.subscriptionStatus = subscriptionInfo.subscriptionStatus;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.totalCredits = subscriptionInfo.totalCredits;
      this.principal.availableCredits = subscriptionInfo.availableCredits;
      this.authService.updatePrincipal(this.principal);
      if (!this.authService.hasValidSubscription()) {
        this.router.navigate([this.authService.getHomePageForUser()]);
      }
    });
  }

  addRemoteDevice(): void {
    if (this.newRemoteDeviceId && this.newLabel) {
      this.mobileService.addRemoteDevice(this.newRemoteDeviceId, this.newLabel).subscribe(success => {
        this.ngOnInit();
      }, error => {
        this.notifService.error(error.error.message);
      });
    } else {
      this.notifService.error('Please fill the person name and device id');
    }
  }


  confirmEditName(device: MobileDeviceListItem): void {
    if (device.newLabel) {
      this.mobileService.updateRemoteDevice({remoteDeviceId: device.id, label: device.newLabel}).subscribe(success => {
        device.label = device.newLabel;
      });
    }
    device.isInEditMode = false;
  }

  deleteDevice(deviceId: string): void {
    this.dialogService.openConfirmDialog('Delete Device', 'Are you sure you want to delete this mobile device?',
      () => {
        this.mobileService.deleteRemoteDevice(deviceId).subscribe(response => {
          this.ngOnInit();
        });
      });
  }
}


