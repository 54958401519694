import {EventEmitter, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DialogService {

  eventEmitter = new EventEmitter<{ title: string, message: string, callback: () => void }>();

    openConfirmDialog(title: string, message: string, callback: () => void): void {
        this.eventEmitter.emit({title, message, callback});
    }

}
