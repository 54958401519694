<div class="wrapper">
  <app-header [isUserDashboard]="isUserDashboard" class="position-relative"></app-header>
  <mat-drawer-container class="dashboard-content">
    <mat-drawer mode="side" opened class="side">
      <div class="flex-space-between flex-column full-width-height">
        <ul class="options-left-list">
            <li routerLink="/dashboard/devices" routerLinkActive="active active-text" class="option-list-item cursor-pointer"
                [class.disabled-item]="hasInvalidSubscription">
              <span><i class="fas fa-mobile-alt icon-item-padding"></i>Mobile Devices</span>
            </li>
            <li routerLink="/dashboard/scan-to-activate" routerLinkActive="active active-text" class="option-list-item cursor-pointer"
                [class.disabled-item]="hasInvalidSubscription">
              <span><i class="fas fa-qrcode icon-item-padding"></i>Scan To Activate</span>
            </li>
          <li routerLink="/dashboard/subscription" routerLinkActive="active active-text" class="option-list-item cursor-pointer"><span><i
            class="far fa-bookmark icon-item-padding"></i>Subscription</span>
          </li>
            <li routerLink="/dashboard/change-password" routerLinkActive="active active-text" class="option-list-item cursor-pointer"><span><i
              class="fas fa-unlock-alt  icon-item-padding"></i>Change Password</span>
            </li>
        </ul>
        <div class="options-left-list">
          <div class="option-list-item context-cursor bold-text" [title]="principal.email">
            <i class="far fa-user icon-item-padding"></i>
            <span [title]="principal.email">{{principal.name || principal.email}}</span>
          </div>
          <div class="option-list-item cursor-pointer bottom-options bold-text" (click)="logout()"><i class="fas fa-sign-out-alt icon-item-padding"></i>Log out</div>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content class="main-content">
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
<app-footer [isUserDashboard]="isUserDashboard"></app-footer>

