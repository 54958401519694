<ng-template #confirmModal let-modal>
  <div mat-dialog-content>
    <div class="modal-content">
      <div class="modal-header">
        {{title}}
      </div>
      <div class="modal-body" style="padding-bottom: 0;">
        <div class="body-message">
          <span class="message dialogs-content">{{message}}</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <mat-dialog-actions align="center">
        <div class="action-btn-cancel">
          <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
        </div>
        <div class="action-btn">
          <button mat-button (click)="submitCallback()">Confirm</button>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
</ng-template>
