import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../core/services/user.service';
import {NotifService} from '../../../core/services/notif.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-add-user',
  templateUrl: './dialog-add-user.component.html',
  styleUrls: ['./dialog-add-user.component.scss']
})
export class DialogAddUserComponent implements OnInit {

  constructor(private fb: FormBuilder, private userService: UserService, private notifService: NotifService, private modalService: MatDialog) {
  }

  addUserForm: FormGroup = this.fb.group(
    {
      id: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]),
      subscriptionStartDate: new FormControl('', Validators.required),
      subscriptionEndDate: new FormControl('', Validators.required),
      totalCredits: new FormControl('', Validators.required),
      availableCredits: new FormControl('', Validators.required),
    });

  ngOnInit(): void {
  }

  submitAddUser(): void {
   if (this.addUserForm.valid) {
    const addRequest = this.addUserForm.value;
    this.userService.addUser(addRequest).subscribe(success => {
       this.notifService.success('User Added');
       this.modalService.closeAll();
     },
     error => {
       this.notifService.error(error.error.message);
     },
    );
   }
  }

}
