import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../core/services/auth.service';
import {User} from '../../model/user/user';
import {UserType} from '../../model/user/user-type';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

  @Input() disableRipple: boolean;
  @Input() isUserDashboard: boolean;


  principal: User;
  isAdmin = false;

  hasInvalidSubscription = false;

  constructor(private authService: AuthService) {
    this.principal = this.authService.getPrincipal();
    this.isAdmin = this.principal.type === UserType.SUPER_ADMIN;
  }


  ngOnInit(): void {
    this.authService.onPrincipalChange().subscribe(principal => {
      if (principal) {
        this.hasInvalidSubscription = !this.authService.hasValidSubscription();
        this.principal = principal;
      }
    });
  }


  logout(): void {
    this.authService.logout();
  }

}
