import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../core/services/auth.service';
import {UserService} from '../../core/services/user.service';
import {NotifService} from '../../core/services/notif.service';


@Component({
    selector: 'app-forgot-password-confirm',
    template: '<span>Loading...</span>'
})
export class ForgotPasswordConfirmationComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                private authService: AuthService,
                private notifService: NotifService) {

    }

    ngOnInit(): void {

        this.activatedRoute.queryParams.subscribe(
            params => {
                const confirmationToken = params['token'];

                this.userService.confirmPasswordChange(confirmationToken)
                    .subscribe(
                        success => {
                            this.notifService.success('Your new password was activated');
                            this.authService.logout();
                        }, error => {
                            if (error.status === 503) {
                                this.notifService.error('Your link is not valid or has expired', 'Error');
                            }
                            this.router.navigate(['/login']);
                        }
                    );
            }
        );

    }


}
