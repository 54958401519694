import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Message} from '../../../../model/message/message';

@Component({
  selector: 'app-dialog-messages',
  templateUrl: './dialog-messages.component.html',
  styleUrls: ['./dialog-messages.component.scss']
})

export class DialogMessagesComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Message) { }

  ngOnInit(): void {
  }

}
