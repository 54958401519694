import {Injectable} from '@angular/core';
import {User} from '../../model/user/user';
import {PaymentStripeRequest} from '../../model/subscription/payment-stripe-request';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {StringResponse} from '../../model/string-response';
import {UpdateSubscriptionRequest} from '../../model/subscription/update-subscription-request';
import {SubscriptionInfo} from '../../model/subscription/subscription-info';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private readonly http: HttpClient) {
  }

  startSubscription(request: PaymentStripeRequest): Observable<User> {
    return this.http.post<User>('/subscriptions', request);
  }

  cancelSubscription(): Observable<User> {
    return this.http.delete<User>('/subscriptions');
  }

  reactivateSubscription(): Observable<User> {
    return this.http.post<User>('/subscriptions/reactivate', null);
  }

  updateSubscription(request: UpdateSubscriptionRequest): Observable<User> {
    return this.http.put<User>('/subscriptions', request);
  }

  getSubscription(): Observable<SubscriptionInfo> {
    return this.http.get<SubscriptionInfo>(`/subscriptions`);
  }

  createSetupIntent(): Observable<StringResponse> {
    return this.http.get<StringResponse>(`/subscriptions/payment-intent`);
  }

}
