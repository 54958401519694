import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective, NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {UserService} from '../../../core/services/user.service';
import {ChangePasswordRequest} from '../../../model/user/change-password-request';
import {AuthService} from '../../../core/services/auth.service';
import {NotifService} from '../../../core/services/notif.service';
import {ErrorStateMatcher} from '@angular/material/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild(FormGroupDirective) myNgForm: any;
  changePassForm: FormGroup;
  matcher = new MyErrorStateMatcher();


  passwordValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('newPass').value;
    const confirmPass = group.get('confirmPass').value;
    console.log(pass, confirmPass);
    return pass === confirmPass ? null : {notSame: true};
  }

  constructor(private fb: FormBuilder, private userService: UserService, private authService: AuthService,
              private notifService: NotifService) {
  }


  ngOnInit(): void {
    this.changePassForm = this.fb.group(
      {
        oldPass: new FormControl('', [Validators.required]),
        newPass: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPass: new FormControl('', [Validators.required]),
      }, {validators: this.passwordValidator});
  }

  submitChangePass(): void {
    if (this.changePassForm.valid) {
      const request: ChangePasswordRequest = {
        userId: this.authService.getPrincipal().id,
        oldPassword: this.changePassForm.controls.oldPass.value,
        newPassword: this.changePassForm.controls.newPass.value,
      };
      this.userService.changePassword(request).subscribe(success => {
        this.notifService.success('Your password has been changed with success');
        this.myNgForm.resetForm();
      }, error => {
        this.notifService.error(error.error.message);
      });
    }
  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}
