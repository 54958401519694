<div class="full-width-height flex-full-centered position-relative">
  <div class="main-containers white-background container-form">
    <div class="change-pass-form">
    <form *ngIf="changePassForm" [formGroup]="changePassForm" (ngSubmit)="submitChangePass()" class="flex-column" autocomplete="off">
      <h2 class="align-self-centered text-color context-cursor">Change Password</h2>
      <div class="align-self-centered shield-icon flex-full-centered"><i class="fas fa-shield-alt"></i></div>
      <h4 class="align-self-centered bold-text blue-color context-cursor">Enter your new password below</h4>
      <mat-form-field appearance="outline">
        <mat-label>Old Password</mat-label>
        <input #oldPassInput matInput placeholder="Old Password" formControlName="oldPass" type="password" name="oldPass" autocomplete="old-password"/>
        <button tabIndex="-1" mat-icon-button matSuffix class="password-eye-icon" type="button" (click)="oldPassInput.type = oldPassInput.type === 'password' ? 'text' : 'password'" [attr.aria-label]="'Hide password'">
          <mat-icon>visibility</mat-icon>
        </button>
        <mat-error *ngIf="changePassForm.invalid">This field is required. Set at least 8 characters</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input #newPassInput matInput placeholder="New Password" formControlName="newPass" type="password" name="newPass" autocomplete="new-password"/>
        <button tabIndex="-1" mat-icon-button matSuffix class="password-eye-icon" type="button" (click)="newPassInput.type = newPassInput.type === 'password' ? 'text' : 'password'" [attr.aria-label]="'Hide password'">
          <mat-icon>visibility</mat-icon>
        </button>
        <mat-error *ngIf="changePassForm.invalid">This field is required. Set at least 8 characters</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Confirm New Password</mat-label>
        <input #confirmPassInput type="password" matInput placeholder="Confirm New Password" formControlName="confirmPass"
               name="confirmPass" autocomplete="new-password" [errorStateMatcher]="matcher"/>
        <button tabIndex="-1" mat-icon-button matSuffix class="password-eye-icon" type="button" (click)="confirmPassInput.type = confirmPassInput.type === 'password' ? 'text' : 'password'" [attr.aria-label]="'Hide password'">
          <mat-icon>visibility</mat-icon>
        </button>
        <mat-error *ngIf="changePassForm.errors?.notSame">
          Passwords do not match
        </mat-error>
      </mat-form-field>
      <button class="btn-custom margin-bottom-25" type="submit">Change Password</button>
    </form>
    </div>
    <div></div>
  </div>
</div>
