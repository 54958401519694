<div class="auth-container">
  <div class="left-section-auth">
    <div class="background-logo">
      <div class="logo-container">
        <img src="../../assets/logos/logo-600w.png" class="logo" alt="Answersure Logo"/>
      </div>
    </div>
    <h3 class="h3-reset-password">Reset your password</h3>
    <h4 class="message-for-users">Enter your email address so we can reset your password</h4>
    <div class="auth-form">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-form-field class="input-fields" appearance="outline">
          <mat-label>Email</mat-label>
          <input type="text" matInput placeholder="Email" formControlName="email"/>
          <mat-error *ngIf="form.invalid">This field is required</mat-error>
        </mat-form-field>

        <div>
          <button mat-button class="btn-custom">Submit</button>

          <div class="redirect-link flex-space-between">
            <span routerLink="/login">Go Back To <span class="bold-text">Login</span></span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="right-section-auth"></div>
</div>
