<div class="text-centered container" style="display: flex; justify-content: center; flex-direction: column; align-items: center">
  <div id="title1">Congratulations!</div>
  <div id="title2">You have successfully created your Answersure account.</div>

  <div id="subtitle">
    In order to use AnswerSure protection features, you need to start a subscription. The free trial includes a 7 day period. After this period you
    will be charged monthly, based on the number of the devices you want. You may cancel or change this subscription anytime.
  </div>
  <div class="button-section">
    <button class="orange-button" mat-raised-button routerLink="/payment">Start your free trial</button>
  </div>

  <div id="footer">

  </div>

</div>
