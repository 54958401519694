import {Injectable} from '@angular/core';


import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Constants} from '../../constants';
import {Observable} from 'rxjs/internal/Observable';
import {tap} from 'rxjs/operators';
import {NotifService} from '../services/notif.service';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptorService {

    constructor(private notifService: NotifService, private authService: AuthService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === Constants.AUTH_ERROR_CODE || err.status === Constants.PERMISSION_DENIED_ERROR_CODE) {
                        this.notifService.error('Your session has expired. Please re-login', 'Session expired');
                        this.authService.logout();
                        return;
                    } else if (err.status !== Constants.BUSINESS_ERROR_CODE) {
                        this.notifService.error('mmm... something went wrong. Please come back later.');
                    } else {
                        throw err;
                        // this.notifService.error('Unknown error code');
                    }
                }
            }));
    }
}
