import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {RegisterComponent} from './pages/register/register.component';
import {SuperadminComponent} from './pages/admin/superadmin.component';
import {ActivationPageComponent} from './pages/register/invitation/activation-page.component';
import {NotAuthenticatedRouteGuard} from './core/guards/not-authenticated.route.guard';
import {AuthenticatedRouteGuard} from './core/guards/authenticated.route.guard';
import {PaymentPageComponent} from './pages/payment/payment-page.component';
import {AdminRouteGuard} from './core/guards/admin.route.guard';
import {MessagesPageComponent} from './pages/admin/messages/messages-page.component';
import {DevicesComponent} from './pages/admin/devices/devices.component';
import {SurveysComponent} from './pages/admin/surveys/surveys.component';
import {SubscriptionActiveRouteGuard} from './core/guards/subscription-active.route.guard';
import {DashboardModule} from './pages/dashboard/dashboard-module/dashboard.module';
import {DashboardPageComponent} from './pages/dashboard/dashboard-page.component';
import {DashboardDevicesComponent} from './pages/dashboard/devices/dashboard-devices.component';
import {SubscriptionComponent} from './pages/dashboard/subscription/subscription.component';
import {ScanToActivateComponent} from './pages/dashboard/scan-to-activate/scan-to-activate.component';
import {ChangePasswordComponent} from './pages/dashboard/change-password/change-password.component';
import {ViewDetailsComponent} from './pages/dashboard/view-details/view-details.component';
import {ForgotPasswordConfirmationComponent} from './pages/forgot-password/forgot-password-confirm.component';


const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedRouteGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthenticatedRouteGuard]
  },
  {
    path: 'forgot-password/confirm',
    component: ForgotPasswordConfirmationComponent,
    canActivate: []
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NotAuthenticatedRouteGuard]
  },
  {
    path: 'dashboard',
    component: DashboardPageComponent,
    children: [
      {path: '', redirectTo: 'devices', pathMatch: 'full'},
      {path: 'devices', component: DashboardDevicesComponent},
      {path: 'subscription', component: SubscriptionComponent},
      {path: 'scan-to-activate', component: ScanToActivateComponent},
      {path: 'change-password', component: ChangePasswordComponent},
      {path: 'devices/:id', component: ViewDetailsComponent},
      {path: '**', component: DashboardDevicesComponent}
    ],
    // loadChildren: () => import('./pages/dashboard/dashboard-module/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthenticatedRouteGuard]
  },
  {
    path: 'activation/:token',
    component: ActivationPageComponent,
  },
  {
    path: 'admin',
    component: SuperadminComponent,
    canActivate: [AdminRouteGuard]
  },
  {
      path: 'admin/messages',
      component: MessagesPageComponent,
      canActivate: [AdminRouteGuard]
  },
  {
    path: 'admin/devices',
    component: DevicesComponent,
    canActivate: [AdminRouteGuard]
  },
  {
    path: 'admin/surveys',
    component: SurveysComponent,
    canActivate: [AdminRouteGuard]
  },
  {
    path: 'payment',
    component: PaymentPageComponent
  },
];

@NgModule({
  imports: [
    DashboardModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
