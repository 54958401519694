<div class="full-width-height flex-full-centered position-relative">
  <div class="main-containers white-background view-subst-container">
    <div class="margin-mobile">
      <app-invalid-subscription *ngIf="principal.subscriptionStatus === SubscriptionStatus.NO_SUBSCRIPTION"></app-invalid-subscription>
      <ng-container *ngIf="principal.subscriptionStatus !== SubscriptionStatus.NO_SUBSCRIPTION">
        <div class="details-row" style="margin-bottom: 24px;"><span>Subscription status:</span> <span class="status" [ngClass]="principal.subscriptionStatus">{{principal.subscriptionStatus}}</span></div>
        <div class="details-row"><span>Email: </span> <span>{{principal.email}}</span></div>
        <div class="details-row">
          <span>Subscription plan: </span> <span>${{principal.totalCredits * perCreditPrice | number: '1.2-2'}} / month ({{principal.totalCredits}} mobile devices) <span class="change-subscription-btn" (click)="openModal(updateSubscriptionTemplate)" *ngIf="hasValidSubscription && principal.subscriptionStatus !== SubscriptionStatus.CANCELED">(Change)</span></span>
        </div>
        <div class="details-row"><span>Subscription started on: </span> <span>{{principal.subscriptionStartDate | date }}</span> </div>
        <div class="details-row" *ngIf="hasValidSubscription && principal.subscriptionStatus !== SubscriptionStatus.CANCELED">
          <span>Next payment: </span>
          <span>{{principal.subscriptionEndDate - nextPaymentBuffer | date}}</span></div>
        <div class="details-row" *ngIf="principal.subscriptionStatus === SubscriptionStatus.ENDED || principal.subscriptionStatus === SubscriptionStatus.CANCELED">
          <span>Subscription end date: </span> <span>{{principal.subscriptionEndDate | date }} </span></div>
        <div class="details-row" style="margin-top: 24px;">
          <button mat-raised-button (click)="cancelSubscription()" *ngIf="principal.subscriptionStatus === SubscriptionStatus.ACTIVE || principal.subscriptionStatus === SubscriptionStatus.TRIALING">Cancel Subscription</button>
          <button (click)="reactivateSubscription()" class="blue-button" mat-button *ngIf="principal.subscriptionStatus === SubscriptionStatus.CANCELED">Reactivate subscription</button>
          <button class="blue-button" routerLink="/payment" mat-button *ngIf="principal.subscriptionStatus === SubscriptionStatus.ENDED">Start a new Subscription</button>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<ng-template #updateSubscriptionTemplate>
  <div mat-dialog-content>
    <form [formGroup]="updateForm" (ngSubmit)="updateSubscription()" autocomplete="off" class="flex-column update-form">
      <p class="total-credits">Actual subscription: <span class="bold-text blue-color">{{principal.totalCredits}}
        mobile devices.</span></p>
      <p class="quantity"> Please specify the new value: </p>
      <mat-form-field class="input-fields" appearance="outline">
        <mat-label>Credits</mat-label>
        <input matInput type="number" [(ngModel)]="newCreditsValue" placeholder="Quantity" formControlName="quantity"
               name="quantity" autocomplete="off"/>
        <mat-error *ngIf="updateForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <div class="actions">
        <mat-dialog-actions align="center">
          <div class="action-btn-cancel">
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
          </div>
          <div class="action-btn">
            <button [disabled]="isLoading" mat-button type="submit">Update</button>
          </div>
        </mat-dialog-actions>
      </div>
    </form>
  </div>
</ng-template>

