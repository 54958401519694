import {
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnInit, TemplateRef,
    ViewChild
} from '@angular/core';
import {DialogService} from './dialog.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnInit {

    @ViewChild('confirmModal', {static: true}) confirmModalTemplate: TemplateRef<any>;

    title: string;
    message: string;
    callback: () => void;

    constructor(private modalService: MatDialog,
                private dialogService: DialogService) {
    }

    ngOnInit(): void {
        this.dialogService.eventEmitter.subscribe(event => {
            this.title = event.title;
            this.message = event.message;
            this.callback = event.callback;
            this.modalService.open(this.confirmModalTemplate, {
              panelClass: 'width-general-dialog',
            });
        });
    }

    submitCallback(): void {
        this.callback();
        this.modalService.closeAll();
    }
}
