<div class="full-width-height flex-full-centered position-relative">
  <div *ngIf="!principal.subscriptionId" class="full-width-height flex-full-centered margin-no-subst-container">
    <div class="main-containers white-background no-subscription-container">
      <div>
        You don't have a subscription yet. Go to <span class="bold-text cursor-pointer" routerLink="/payment">Subscription</span> section in order to use Answersure protection features.
      </div>
    </div>
  </div>
  <div *ngIf="principal.subscriptionId" class="main-containers white-background qr-container" [ngClass]="isLoading ? 'loading-container' : ''">
    <ng-container *ngIf="isLoading">
      <div class="flex-column flex-full-centered">
        <h2 class="text-color margin-bottom-25">Loading...</h2>
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && principal">
      <div class="info-label">Activate your phone scanning the QR code</div>
      <div class="qr-code flex-full-centered">
        <qr-code [value]="principal.activationToken" size="200" errorCorrectionLevel="M"></qr-code>
      </div>
      <div class="flex-full-centered token-container" style="flex-direction: column">
        <div class="text-centered" style="margin-bottom: 18px;font-size: 14px;opacity: 0.7;">Or manually copy the token below:</div>
        <div class="flex-full-centered">
          <div class="token dark-blue-color">{{principal.activationToken}}</div>
          <div class="cursor-pointer btn-copy-token" [cdkCopyToClipboard]="principal.activationToken"
               (click)="copyToken()"><i class="fas fa-copy"></i></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


