import {Component, OnChanges, OnInit} from '@angular/core';
import {User} from '../../../model/user/user';
import {MobileDeviceListItem} from '../../../model/device/mobile-device-list-item';
import {AuthService} from '../../../core/services/auth.service';
import {MobileService} from '../../../core/services/mobile.service';
import {SubscriptionService} from '../../../core/services/subscription.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifService} from '../../../core/services/notif.service';
import {DialogService} from '../../../components/dialog/dialog.service';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})

export class ViewDetailsComponent implements OnInit {

  principal: User;
  devices: MobileDeviceListItem[];
  device: MobileDeviceListItem;

  constructor(private authService: AuthService, private mobileService: MobileService, private subscriptionService: SubscriptionService,
              private route: ActivatedRoute, private notifService: NotifService, private dialogService: DialogService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.principal = this.authService.getPrincipal();
    const id = this.route.snapshot.params.id;
    this.mobileService.getDeviceById(id).subscribe(device => {
      this.device = device;
      return {...device, isInEditMode: false, newLabel: device.label};
    }, error => {
      this.notifService.error(error.error.message);
    });
    this.fetchSubscriptionInfo();
  }

  fetchSubscriptionInfo(): void {
    this.subscriptionService.getSubscription().subscribe(subscriptionInfo => {
      this.principal.subscriptionStatus = subscriptionInfo.subscriptionStatus;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.totalCredits = subscriptionInfo.totalCredits;
      this.principal.availableCredits = subscriptionInfo.availableCredits;
      this.authService.updatePrincipal(this.principal);
    });
  }

  confirmEditName(device: MobileDeviceListItem): void {
    if (device.newLabel) {
      this.mobileService.updateRemoteDevice({remoteDeviceId: device.id, label: device.newLabel}).subscribe(success => {
        device.label = device.newLabel;
      });
    }
    device.isInEditMode = false;
  }

  deleteDevice(deviceId: string): void {
    this.dialogService.openConfirmDialog('Delete Device', 'Are you sure you want to delete this mobile device?',
      () => {
        this.mobileService.deleteRemoteDevice(deviceId).subscribe(response => {
          this.ngOnInit();
          this.router.navigate(['/dashboard/devices']);
        });
      });
  }


}
