<div class="position-relative">
  <div *ngIf="principal.subscriptionId">
    <div class="flex top-cards">
      <div class="devices-info-cards white-background main-containers context-cursor">
        <div class="devices-icon"><i class="fas fa-phone"></i></div>
        <div>
          <div class="devices-info-number">{{principal.totalCredits - principal.availableCredits}}</div>
          <div class="devices-info">Activated Devices</div>
        </div>
      </div>
      <div class="devices-info-cards white-background main-containers context-cursor">
        <div class="devices-icon"><i class="fas fa-phone-slash"></i></div>
        <div>
          <div class="devices-info-number">{{principal.availableCredits}}</div>
          <div class="devices-info">Unactivated Devices</div>
        </div>
      </div>
    </div>
    <div class="activated-devices-list white-background main-containers context-cursor">
      <div class="text-color list-title">Remote Subscriptions</div>
      <div *ngIf="!devices?.length" class="no-devices-message">
        You currently have no remote devices activated.
      </div>
      <div class="devices-web-list">
        <ng-container *ngIf="devices?.length">
          <div class="flex-space-between list-item margin-bottom">
            <span class="text-color list-header flex-full-centered os-icon">OS</span>
            <span class="text-color list-header flex-full-centered name-label">Person Name</span>
            <span class="text-color list-header flex-full-centered device-id">Mobile Device Id</span>
            <span class="text-color list-header flex-full-centered actions">Actions</span>
          </div>
          <div class="devices">
            <div *ngFor="let device of devices; let i = index" class="flex-space-between list-item">

            <span class="device-icon flex-full-centered os-icon"><i class="fab" [ngClass]="{'fa-android android': device.isAndroid, 'fa-apple apple' : !device.isAndroid}"></i></span>

              <span *ngIf="!device.isInEditMode" class="font-15 flex-full-centered name-label">{{device.isOwned ? 'Your phone*' : device.label}}</span>
              <span *ngIf="device.isInEditMode" class="flex-full-centered name-label">
              <input [(ngModel)]="device.newLabel" type="text" name="name-label" autocomplete="off"
                     class="base-input font-15 name-input">
            </span>
              <span class="font-15 flex-full-centered device-id">{{device.id}}</span>
              <span *ngIf="!device.isInEditMode" class="flex-full-centered actions">
             <span (click)="device.isInEditMode = true;" class="icon-right-margin cursor-pointer icons-color"><i
               class="far fa-edit"></i></span>
             <span (click)="deleteDevice(device.id)" class="cursor-pointer icons-color"><i
               class="far fa-trash-alt"></i></span>
            </span>
              <span *ngIf="device.isInEditMode" class="flex-full-centered actions">
              <span (click)="confirmEditName(device)"
                    class="cursor-pointer icon-right-margin font-17 icons-color"><i
                class="far fa-check-circle"></i></span>
              <span (click)="device.isInEditMode = false; device.newLabel = device.label;"
                    class="cursor-pointer font-17 icons-color"><i class="far fa-times-circle"></i></span>
            </span>
            </div>

          </div>
        </ng-container>
      </div>

      <div class="devices-phone-list" *ngIf="devices?.length">
        <div class="flex-space-between padding-list-item margin-bottom">
          <div class="blue-color bold-text width-list-item-os align-self-centered">OS</div>
          <div class="blue-color bold-text width-list-item align-self-centered">Name</div>
          <div class="blue-color bold-text width-list-item-details align-self-centered">Details</div>
        </div>
        <div *ngFor="let device of devices">
          <div class="flex-space-between padding-list-item row-list">
            <div class="width-list-item-os align-self-centered">

            <span class="device-icon"><i class="fab" [ngClass]="{'fa-android android': device.isAndroid, 'fa-apple apple' : !device.isAndroid}"></i></span>

            </div>
            <div class="width-list-item d-label align-self-centered">{{device.label}}</div>
            <div [routerLink]="['/dashboard/devices', device.id]" class="width-list-item-details align-self-centered">
              <span class="btn-view-details">View Details</span></div>
          </div>
        </div>
      </div>


      <div class="add-remote">
        <div class="label">
          <input [(ngModel)]="newLabel" placeholder="Person Name" type="text" name="label" autocomplete="off"
                 class="base-input">
        </div>
        <div class="id">
          <input [(ngModel)]="newRemoteDeviceId" placeholder="Remote Answersure Id" type="text" name="remoteId"
                 autocomplete="off" class="base-input">
        </div>
        <div class="mobile-btn-width">
          <button (click)="addRemoteDevice()">Add Mobile Device</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!principal.subscriptionId" class="full-width-height flex-full-centered margin-no-subst-container">
    <div class="main-containers white-background no-subscription-container">
      <div>
        You don't have a subscription yet. Go to <span class="bold-text cursor-pointer" routerLink="/payment">Subscription</span> section in order to use Answersure protection features.
      </div>
    </div>
  </div>
</div>
