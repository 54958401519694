<div class="auth-container">
    <div class="left-section-auth flex-column" [class.flex-centered]="registrationSuccess">
      <div class="background-logo">
        <div class="logo-container"[ngClass]="registrationSuccess ? 'register-logo': ''">
          <img src="../../../assets/logos/logo-600w.png" class="logo" alt="Answersure Logo"/>
        </div>
      </div>
      <div *ngIf="!registrationSuccess" class="auth-form" autocomplete="off">
        <form [formGroup]="registrationForm" (submit)="submitRegisterForm()" class="flex-column">
          <mat-form-field class="input-fields" appearance="outline">
            <mat-label>Your Name</mat-label>
            <input type="text" matInput placeholder="Your Full Name" formControlName="name" name="user-name" autocomplete="off"/>
            <mat-error *ngIf="registrationForm.invalid">This field is required</mat-error>
          </mat-form-field>
          <mat-form-field class="input-fields" appearance="outline">
            <mat-label>Email</mat-label>
            <input type="text" matInput placeholder="Email" formControlName="email" name="email" autocomplete="off"/>
            <mat-error *ngIf="registrationForm.invalid">This field is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input #passwordInput type="password" matInput placeholder="Password" formControlName="password" name="user-pass" autocomplete="change-password"/>
            <button mat-icon-button matSuffix class="password-eye-icon" type="button"
                    (click)="passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password'"
                    [attr.aria-label]="'Hide password'">
              <mat-icon>visibility</mat-icon>
            </button>
            <mat-error *ngIf="registrationForm.invalid">This field is required</mat-error>
          </mat-form-field>

          <div>
            <button type="submit" mat-button class="btn-custom">Register</button>

            <div class="redirect-link">
              <span routerLink="/login">Already a member? <span class="bold-text">Login</span></span>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="registrationSuccess" class="register-success-message">
        <span>Thank you. We've sent an activation link to your email address.</span>
        <div class="margin-top">
          <span routerLink="/login" class="go-to-login-link cursor-pointer">Go to <span
            class="bold-text">login</span></span>
        </div>
      </div>
    </div>
  <div class="right-section-auth"></div>
</div>
