<div class="activation-container">
  <ng-container *ngIf="isLoading">
    <mat-spinner [diameter]="40"></mat-spinner>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <div class="flex-column">
      <div class="logo">
        <img src="../../../../assets/logos/logo-full-h50.png" alt="Answersure Logo">
      </div>
      <div *ngIf="!tokenIsValid" class="activation-message">
        Sorry, your link is either invalid or expired
      </div>
      <div *ngIf="tokenIsValid" class="activation-message flex-column">
        <div>
          Your account was activated with success.
        </div>
        <div routerLink="/login" class="btn-custom margin-top">Go to login</div>
      </div>
    </div>
  </ng-container>
</div>
