import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistrationService} from '../../../core/services/registration.service';

@Component({
  selector: 'app-invitation-page',
  templateUrl: './activation-page.component.html',
  styleUrls: ['./activation-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationPageComponent implements OnInit {

  tokenIsValid = true;
  isLoading = true;

  constructor(private activatedRoute: ActivatedRoute,
              private registrationService: RegistrationService,
              private router: Router) {
  }

  ngOnInit(): void {
    const invitationToken = this.activatedRoute.snapshot.paramMap.get('token');
    console.log(invitationToken);
    this.registrationService.confirmRegistration(invitationToken).subscribe(success => {
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.tokenIsValid = false;
    });
  }

}
