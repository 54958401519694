import {ChangeDetectorRef, Component, DoCheck, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../core/services/user.service';
import {UpdateUserRequest} from '../../../model/user/update-user-request';
import {NotifService} from '../../../core/services/notif.service';

@Component({
  selector: 'app-dialog-edit-user',
  templateUrl: './dialog-edit-user.component.html',
  styleUrls: ['./dialog-edit-user.component.scss']
})
export class DialogEditUserComponent implements OnInit, DoCheck {

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: UpdateUserRequest, private cd: ChangeDetectorRef, private userService: UserService, private notifService: NotifService,
              private modalService: MatDialog) {
  }

  editUserForm: FormGroup = this.fb.group(
    {
      id: new FormControl(''),
      name: new FormControl(this.data.name, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      email: new FormControl(this.data.email, [Validators.required, Validators.email, Validators.maxLength(100)]),
      subscriptionStartDate: new FormControl(this.data.subscriptionStartDate),
      subscriptionEndDate: new FormControl(this.data.subscriptionEndDate),
      totalCredits: new FormControl(this.data.totalCredits, Validators.required),
      availableCredits: new FormControl(this.data.availableCredits, Validators.required),
    });

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    this.cd.detectChanges();
  }

  submitEditForm(): void {
    if (this.editUserForm.valid) {
      const updateRequest: UpdateUserRequest = this.editUserForm.value;
      this.userService.updateUser(updateRequest).subscribe(success => {
          this.notifService.success('User Edited');
          this.modalService.closeAll();
        },
        error => {
          this.notifService.error(error.error.message);
        },
      );
    }
  }
}
