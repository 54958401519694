<div class="page-container">
  <div class="sections-container">
    <div class="left-section">
      <div class="flex-space-between header-pay">
        <div class="logo">
          <img src="../../../assets/logos/logo-full-h50.png" class="answersure-logo" alt="Answersure Logo"/>
        </div>
        <div  routerLink="/dashboard/subscription" class="cursor-pointer align-self-centered bold-text back-to-dashboard">Back to Dashboard</div>
      </div>
      <div class="form-container">
        <div class="form">
          <div class="title-heading">
            <h2 class="text-centered">Let's finish powering you up!</h2>
            <h3 class="text-centered">Start Your Subscription Now</h3>
          </div>
          <div class="centered-form">
            <form [formGroup]="paymentForm" (ngSubmit)="submitPayment()">
              <input formControlName="name" class="stripe-input input" type="text" placeholder="Full Name"/>
              <input formControlName="phoneNumber" class="stripe-input input" type="text" placeholder="Phone Number"/>
              <div formGroupName="address">
                <div class="flex-space-between">
                  <input formControlName="line1" class="stripe-input address-input" type="text"
                         placeholder="Street Address" required>
                  <input formControlName="line2" class="stripe-input address-input" type="text"
                         placeholder="Apt, Suite, Floor, etc*">

                </div>
                <div class="flex-space-between">

                  <input formControlName="city" class="stripe-input address-input" type="text" placeholder="City*">


                  <input formControlName="country" class="stripe-input address-input" type="text"
                         placeholder="Country"
                         required>

                </div>
                <div class="flex-space-between">
                  <input formControlName="state" class="stripe-input address-input" type="text" placeholder="State">
                  <input formControlName="postalCode" class="stripe-input address-input" type="text"
                         placeholder="Postal Code*">
                </div>
              </div>
              <ngx-stripe-card
                [options]="cardOptions"
                [elementsOptions]="elementsOptions"
              ></ngx-stripe-card>
              <div>
                <label class="label">Mobile Devices</label>
                <input formControlName="credits" class="stripe-input credits-input" type="number"/>
              </div>
              <div *ngIf="!isLoading">
                <button type="submit" class="btn-custom margin-top-15">
                  PAY ${{Math.max(paymentForm.controls.credits.value, 1) * PER_CREDIT_PRICE}}
                </button>
              </div>
              <div *ngIf="isLoading" class="margin-top-15 flex-centered">
                <mat-spinner [diameter]="30"></mat-spinner>
              </div>
              <div class="footer">
                <span *ngIf="!principal.stripeCustomerId">After the trial period of {{trialPeriod}} days, you will be charged monthly. </span>
                <span *ngIf="principal.stripeCustomerId">You will be charged monthly. </span>
                <span>You can cancel this subscription anytime.</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="right-section"></div>
  </div>
</div>
