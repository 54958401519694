import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {UserService} from '../../core/services/user.service';
import {NotifService} from '../../core/services/notif.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]),
  });

  constructor(private userService: UserService, private notifService: NotifService) {
  }

  ngOnInit(): void {
  }

  submit(): void {
    console.log('submited email');
    if (this.form.valid) {
      this.userService.forgotPassword(this.form.get('email').value).subscribe(success => {
        this.notifService.success('A reset link has been sent to your email address');
        this.formGroupDirective.resetForm();
      }, error => {
        this.notifService.error(error.error.message);
      });
    }
  }
}
