import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogCreateSurveyComponent} from './dialog-create-survey/dialog-create-survey.component';
import {SurveyService} from '../../../core/services/survey.service';
import {DialogEditSurveyComponent} from './dialog-edit-survey/dialog-edit-survey.component';
import {SurveyReportItem} from '../../../model/survey/survey-report-item';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})

export class SurveysComponent implements OnInit {

  displayedColumns: string[] = ['name', 'answers', 'iosAnswers', 'androidAnswers', 'actions'];
  surveyItems: SurveyReportItem[] = [];

  constructor(public dialog: MatDialog, private surveyService: SurveyService) {
  }

  ngOnInit(): void {
    this.surveyService.getSurveyReports().subscribe(surveys => this.surveyItems = surveys);
  }

  openCreateSurveyDialog(): void {
    const matDialogRef = this.dialog.open(DialogCreateSurveyComponent, {
      panelClass: 'width-surveys-dialog',
      autoFocus: false,
    });
    matDialogRef.componentInstance.onCreateSuccessEvent.subscribe(survey => this.onCreatedSurvey());
  }

  openEditSurveyDialog(survey: SurveyReportItem): void {
    const matDialogRef = this.dialog.open(DialogEditSurveyComponent , {
      autoFocus: false,
      panelClass: 'width-surveys-dialog',
      data: survey.surveyId
    });
    matDialogRef.componentInstance.onEditSuccessEvent.subscribe(survey => this.onEditedSurvey());
  }

  onCreatedSurvey(): void {
    this.ngOnInit();
    this.dialog.closeAll();
  }

  onEditedSurvey(): void {
    this.ngOnInit();
    this.dialog.closeAll();
  }
}
