import {Component, OnInit} from '@angular/core';
import {User} from '../../../model/user/user';
import {MobileService} from '../../../core/services/mobile.service';
import {AuthService} from '../../../core/services/auth.service';
import {NotifService} from "../../../core/services/notif.service";
import {Router} from '@angular/router';

@Component({
  selector: 'app-scan-to-activate',
  templateUrl: './scan-to-activate.component.html',
  styleUrls: ['./scan-to-activate.component.scss', '../devices/dashboard-devices.component.scss']
})

export class ScanToActivateComponent implements OnInit {

  principal: User;
  isLoading = true;
  hasPhoneConfirmed = false;


  constructor(private mobileService: MobileService, private authService: AuthService, private  notifService: NotifService,
              private router: Router) {
  }

  ngOnInit(): void {
    if (!this.authService.hasValidSubscription()) {
      this.router.navigate([this.authService.getHomePageForUser()]);
    }
    this.principal = this.authService.getPrincipal();
    this.mobileService.getMobileDevicesForUser().subscribe(devices => {
      devices.forEach(device => {
        if (device.isOwned) {
          this.hasPhoneConfirmed = true;
        }
      });
      this.isLoading = false;
    });
  }

  copyToken(): void{
    this.notifService.success('Token copied!');
  }

}
