import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotifService {

    constructor(private snackBar: MatSnackBar) {
    }

    success(message: string): void {
        this.openSnackBar(message, '', 'snack-bar-success');
    }

    error(message: string, shortMessage?: string): void {
        this.openSnackBar(message, '', 'snack-bar-error');
    }

    private openSnackBar(message: string, action: string, cssClass: string): void {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: cssClass
        });
    }
}
