<div class="wrapper">
  <app-header></app-header>
  <div class="table-container page-width">
    <div class="btn-actions-container top-table-container align-flex-end">
      <button mat-button [disableRipple]="true" (click)="openCreateSurveyDialog()">Create Survey</button>
    </div>

    <table mat-table [dataSource]="surveyItems">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let survey" class="content-ellipsis"> {{survey.name}} </td>
      </ng-container>
      <ng-container matColumnDef="answers">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Answers</th>
        <td mat-cell *matCellDef="let survey" class="text-centered">
         {{survey.androidAnswers + survey.iosAnswers}}
        </td>
      </ng-container>
      <ng-container matColumnDef="iosAnswers">
        <th mat-header-cell *matHeaderCellDef class="text-centered">iOS</th>
        <td mat-cell *matCellDef="let survey" class="text-centered">
          {{(100 * survey.iosAnswers / (survey.androidAnswers + survey.iosAnswers)) || 0 | number : '1.0-2'}}%
        </td>
      </ng-container>
      <ng-container matColumnDef="androidAnswers">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Android</th>
        <td mat-cell *matCellDef="let survey" class="text-centered">
          {{100 * survey.androidAnswers / (survey.androidAnswers + survey.iosAnswers) || 0 | number : '1.0-2'}}%
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th class="actions-cell" mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="actions-cell" mat-cell *matCellDef="let survey" class="text-centered">
          <span class="actions-icons" (click)="openEditSurveyDialog(survey)"><i class="fas fa-edit actions-icon"></i></span>
<!--          <span class="edit-trash-icons"><i class="fa fa-trash-alt actions-icon"></i></span>-->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<app-footer></app-footer>
