<div class="wrapper">
  <app-header></app-header>
  <div class="table-container page-width">
    <div class="top-table-container flex-space-between">
      <div class="filter-input">
        <mat-form-field>
          <mat-label>Search user</mat-label>
          <input matInput (keyup)="applyFilter(searchInput.value)" placeholder="" #searchInput name="search"
                 autocomplete="off">
        </mat-form-field>
      </div>
      <div class="btn-actions-container">
      </div>
    </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let user" class="content-ellipsis cursor-pointer" matTooltip="{{user.name}}"
            matTooltipPosition="above" matTooltipClass="tooltip-class">{{user.name}}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let user" class="content-ellipsis cursor-pointer" matTooltip="{{user.email}}"
            matTooltipPosition="above" matTooltipClass="tooltip-class">{{user.email}}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="text-centered">User Status</th>
        <td mat-cell *matCellDef="let user" class="text-centered boolean-cell"><span class="status"
                                                                                     [ngClass]="'bg-status-'+ user.status">{{user.status}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="subscriptionStatus">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Subscription Status</th>
        <td mat-cell *matCellDef="let user" class="text-centered"><span class="status"
                                                                        [ngClass]="'bg-status-'+ user.subscriptionStatus ">{{user.subscriptionStatus}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="subscriptionStartDate">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Subscription Start</th>
        <td mat-cell *matCellDef="let user"
            class="text-centered"> {{user.subscriptionStartDate | answersureDate }} </td>
      </ng-container>
      <ng-container matColumnDef="subscriptionEndDate">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Subscription End</th>
        <td mat-cell *matCellDef="let user" class="text-centered">{{user.subscriptionEndDate | answersureDate }}</td>
      </ng-container>
      <ng-container matColumnDef="totalCredits">
        <th mat-header-cell *matHeaderCellDef class="text-centered"> Total Credits</th>
        <td mat-cell *matCellDef="let user" class="text-centered">{{user.totalCredits}}</td>
      </ng-container>
      <ng-container matColumnDef="availableCredits">
        <th mat-header-cell *matHeaderCellDef class="text-centered"> Available Credits</th>
        <td mat-cell *matCellDef="let user" class="text-centered">{{user.availableCredits}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th class="text-centered" mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="text-centered" mat-cell *matCellDef="let user">
          <!--          <span class="actions-icons" (click)="openDialogEdit(user)"><i class="fas fa-edit actions-icon"></i></span>-->
          <span class="actions-icons" (click)="deleteUser(user)"><i class="fa fa-trash-alt actions-icon"
                                                                    title="Delete"></i></span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<app-footer></app-footer>
