import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SurveyService} from '../../../../core/services/survey.service';
import {Survey} from '../../../../model/survey/survey';
import {NotifService} from '../../../../core/services/notif.service';

@Component({
  selector: 'app-dialog-create-survey',
  templateUrl: './dialog-create-survey.component.html',
  styleUrls: ['./dialog-create-survey.component.scss']
})

export class DialogCreateSurveyComponent implements OnInit {

  @Output('onCreateSuccess') onCreateSuccessEvent = new EventEmitter<Survey>();

  surveyForm: FormGroup;
  showTranslatedQuestion = false;


  constructor(private fb: FormBuilder, private surveyService: SurveyService, private readonly notifService: NotifService) {
  }

  ngOnInit(): void {
    this.initForm();
    const checkValue: boolean = this.surveyForm.get('hasTranslate').value;
    if (checkValue) {
      this.addTranslateValidators();
    }
    this.updateConditionalValidators();
  }

  updateConditionalValidators(): void {
    const checkValue: boolean = this.surveyForm.get('hasTranslate').value;
    if (checkValue) {
      this.addTranslateValidators();
    } else {
      this.removeTranslateValidators();
    }
    this.surveyForm.get('hasTranslate').valueChanges.subscribe(value => {
      if (value) {
        this.addTranslateValidators();
      } else {
        this.removeTranslateValidators();
      }
    });
    this.updateValueAndValidityForControl();
  }

  initForm(): void {
    this.surveyForm = this.fb.group({
      id: new FormControl(-1),
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      hasTranslate: new FormControl(false, Validators.required),
      questions: this.fb.array([
        this.createQuestion()
      ]),
    });
  }

  createQuestion(): FormGroup {
    return this.fb.group({
      id: new FormControl(-1),
      question1: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      question2: new FormControl('', [Validators.maxLength(256)]),
      answers: this.fb.array([
        this.createAnswer()
      ]),
      hasMultipleChoice: new FormControl(false, Validators.required),
      hasOtherAnswer: new FormControl(false, Validators.required)
    });
  }

  createAnswer(): FormGroup {
    return this.fb.group({
      id: new FormControl(-1),
      answer1: new FormControl('', [Validators.required, Validators.maxLength(256)]),
      answer2: new FormControl('', [Validators.maxLength(256)]),
    });
  }

  get questions(): any {
    return this.surveyForm.get('questions') as FormArray;
  }

  addQuestion(): void {
    this.questions.push(this.createQuestion());
    this.updateConditionalValidators();
  }

  addAnswer(question: any): void {
    question.controls.answers.push(this.createAnswer());
    this.updateConditionalValidators();
  }

  removeQuestion(index: number): void {
    this.questions.removeAt(index);
  }

  removeAnswer(set: any, index: number): void {
    set.controls.answers.removeAt(index);
  }

  addTranslateValidators(): void {
    this.showTranslatedQuestion = true;
    this.questions.controls.forEach((set: any) => {
      set.get('question2').setValidators(Validators.required);
      set.controls.answers.controls.forEach((answer: any) => {
        answer.get('answer2').setValidators(Validators.required);
      });
    });
  }

  removeTranslateValidators(): void {
    this.showTranslatedQuestion = false;
    this.questions.controls.forEach((set: any) => {
      set.get('question2').clearValidators();
      set.controls.answers.controls.forEach((answer: any) => {
        answer.get('answer2').clearValidators();
      });
    });
  }

  updateValueAndValidityForControl(): void {
    this.questions.controls.forEach((set: any) => {
      set.get('question2').updateValueAndValidity();
      set.controls.answers.controls.forEach((answer: any) => {
        answer.get('answer2').updateValueAndValidity();
      });
    });
  }

  submitCreateForm(): void {
    if (this.surveyForm.valid) {
      const request: Survey = this.surveyForm.value;
      if (!request.hasTranslate) {
        request.questions.forEach((question) => {
          question.question2 = '';
          question.answers.forEach((answer) => {
            answer.answer2 = '';
          });
        });
      }
      console.log(request);
      this.surveyService.createSurvey(request).subscribe(survey => {
        this.onCreateSuccessEvent.emit(survey);
      }, error => {
        this.notifService.error(error.error.message);
      });
    } else {
      console.log('invalid form');
    }
  }

}
