import {Component, OnInit} from '@angular/core';
import {MessageService} from '../../../core/services/message.service';
import {Message} from '../../../model/message/message';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {NotifService} from '../../../core/services/notif.service';
import {DialogMessagesComponent} from './dialog-messages/dialog-messages.component';
import {SurveyService} from '../../../core/services/survey.service';
import {Survey} from '../../../model/survey/survey';
import {SendMessageRequest} from '../../../model/message/send-message-request';


@Component({
  selector: 'app-messages-page',
  templateUrl: './messages-page.component.html',
  styleUrls: ['./messages-page.component.scss']
})
export class MessagesPageComponent implements OnInit {

  sendessageForm: FormGroup;
  messagesTable = new MatTableDataSource([]);
  displayedColumns: string[] = ['title', 'message', 'survey', 'survey-title', 'createdOn'];
  isTimeDisplayed = true;

  surveys: Survey[];

  constructor(private readonly messageService: MessageService,
              private modalService: MatDialog,
              private notifService: NotifService,
              private surveyService: SurveyService) {
  }

  ngOnInit(): void {
    this.messageService.getMessages(null).subscribe(messages => {
      this.messagesTable = new MatTableDataSource<Message>(messages);
    });
    this.initForm();
    this.surveyService.getAllSurveys().subscribe(surveys => this.surveys = surveys);
  }

  initForm(): void {
    this.sendessageForm = new FormGroup({
        title: new FormControl('', [Validators.maxLength(100), Validators.required]),
        message: new FormControl('', [Validators.maxLength(256), Validators.required]),
        surveyId: new FormControl(undefined),
        surveyTitle: new FormControl('Start Survey'),
      }
    );
    this.sendessageForm.get('surveyId').valueChanges.subscribe(value => {
      if (value) {
        this.sendessageForm.get('surveyTitle').setValidators(Validators.required);
      } else {
        this.sendessageForm.get('surveyTitle').clearValidators();
      }
    });
  }

  openModal(content: any): void {
    this.modalService.open(content, {
      panelClass: 'width-message-dialog',
    });
  }

  sendMessage(): void {
    if (this.sendessageForm.valid) {
      const request: SendMessageRequest = this.sendessageForm.value;
      console.log(request);
      this.messageService.sendMessage(request).subscribe((success) => {
        this.ngOnInit();
        this.modalService.closeAll();
        this.notifService.success('Message Added');
      });
    }
  }

  openDialogMessages(row: Message): void {
    this.modalService.open(DialogMessagesComponent, {
      data: row,
      panelClass: 'width-messages-dialog',
      autoFocus: false
    });
  }
}
