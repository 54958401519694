import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RegistrationRequest} from '../../model/registration-request';

@Injectable({providedIn: 'root'})
export class RegistrationService {

  constructor(private readonly http: HttpClient) {
  }

  register(request: RegistrationRequest): Observable<void> {
    return this.http.post<void>('/register', request);
  }

  confirmRegistration(token: string): Observable<void> {
    return this.http.get<void>(`/register/confirm/${token}`);
  }

}
