import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SurveyService} from '../../../../core/services/survey.service';
import {Survey} from '../../../../model/survey/survey';
import {NotifService} from '../../../../core/services/notif.service';

@Component({
  selector: 'app-dialog-edit-survey',
  templateUrl: './dialog-edit-survey.component.html',
  styleUrls: ['./dialog-edit-survey.component.scss']
})

export class DialogEditSurveyComponent implements OnInit {

  @Output('onEditSuccess') onEditSuccessEvent = new EventEmitter<Survey>();

  editSurveyForm: FormGroup;
  loading = false;
  showTranslatedQuestion: boolean;

  constructor(private fb: FormBuilder,
              private surveyService: SurveyService,
              @Inject(MAT_DIALOG_DATA) public data: number, private notifService: NotifService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.surveyService.getSurvey(this.data).subscribe(survey => {
      this.loading = false;
      this.showTranslatedQuestion = survey.hasTranslate;
      this.editSurveyForm = this.fb.group({
        name: new FormControl(survey.name, Validators.required),
        hasTranslate: new FormControl(survey.hasTranslate, Validators.required),
        questions: this.fb.array(survey.questions.map(question => {
          return this.fb.group({
            id: new FormControl(question.id, Validators.required),
            question1: new FormControl(question.question1, Validators.required),
            question2: new FormControl(question.question2),
            answers: this.fb.array(question.answers.map(answer => {
              return this.fb.group({
                id: new FormControl(answer.id, Validators.required),
                answer1: new FormControl(answer.answer1, Validators.required),
                answer2: new FormControl(answer.answer2),
              });
            })),
            hasMultipleChoice: new FormControl(question.hasMultipleChoice, Validators.required),
            hasOtherAnswer: new FormControl(question.hasOtherAnswer, Validators.required)
          });
        })),
      });
      const checkValue: boolean = this.editSurveyForm.get('hasTranslate').value;
      if (checkValue) {
        this.addTranslateValidators();
      }
      this.editSurveyForm.get('hasTranslate').valueChanges.subscribe(value => {
        if (value) {
          this.addTranslateValidators();
        } else {
          this.removeTranslateValidators();
        }
        this.updateValueAndValidityForControl();
      });
    });
  }

  get questions(): any {
    return this.editSurveyForm.get('questions') as FormArray;
  }

  get answers(): any {
    return this.editSurveyForm.get(['questions', 'answers']) as FormArray;
  }

  addTranslateValidators(): void {
    this.showTranslatedQuestion = true;
    this.questions.controls.forEach((set: any) => {
      set.get('question2').setValidators(Validators.required);
      set.controls.answers.controls.forEach((answer: any) => {
        answer.get('answer2').setValidators(Validators.required);
      });
    });
  }

  removeTranslateValidators(): void {
    this.showTranslatedQuestion = false;
    this.questions.controls.forEach((set: any) => {
      set.get('question2').clearValidators();
      set.controls.answers.controls.forEach((answer: any) => {
        answer.get('answer2').clearValidators();
      });
    });
  }

  updateValueAndValidityForControl(): void {
    this.questions.controls.forEach((set: any) => {
      set.get('question2').updateValueAndValidity();
      set.controls.answers.controls.forEach((answer: any) => {
        answer.get('answer2').updateValueAndValidity();
      });
    });
  }

  submitEditForm(): void {
    if (this.editSurveyForm.valid) {
      const request: Survey = this.editSurveyForm.value;
      if (!request.hasTranslate) {
        request.questions.forEach((question) => {
          question.question2 = '';
          question.answers.forEach((answer) => {
            answer.answer2 = '';
          });
        });
      }
      console.log(request);
      this.surveyService.updateSurvey(request).subscribe(survey => {
        this.onEditSuccessEvent.emit(survey);
      }, error => {
        this.notifService.error(error.error.message);
      });
    } else {
      console.log('invalid form');
    }
  }


}
