import {Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Constants} from '../../constants';
import {AuthService} from '../../core/services/auth.service';
import {NotifService} from '../../core/services/notif.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(Constants.EMAIL_REGEX), Validators.maxLength(100)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  constructor(private authService: AuthService, private router: Router, private notifService: NotifService) {
  }

  ngOnInit(): void {
  }

  submit(): void {
    if (this.loginForm.valid) {
      const emailValue = this.loginForm.get('email').value;
      const passwordValue = this.loginForm.get('password').value;
      this.authService.login({email: emailValue, password: passwordValue}).subscribe(
        success => {
          this.router.navigate([this.authService.getHomePageForUser()]);
        },
        error => {
          if (error.status === Constants.BUSINESS_ERROR_CODE) {
            this.notifService.error('Invalid credentials');
          }
          console.warn(error);
        }
      );
    }
  }
}
