// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  trialPeriod: 7,
  perCreditPrice: 4.99,
  baseDomain: 'https://demo.app.answersure.com/api',
  stripeApiKey: 'pk_test_51JLVAyLlIMnJjwwgJWqVT5n5WpMgSYZNe8zoILEHLjRJ6TN9lg15ksN6z4JuLXUMSL3SDFFERILlnhOXgBeTuEIX00CU7TtI3m'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
