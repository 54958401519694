<footer class="footer flex">
  <div class="footer-content flex" [ngClass]="isUserDashboard ? 'page-width-dashboard' : 'page-width'">
    <div class="copyright">©{{currentYear}} Answer Sure, LLC</div>
    <div class="terms-links">
        <a href="https://www.answersure.com/terms-of-use" target="_blank"><span>Terms</span></a>
        <span class="line">|</span>
        <a href="https://www.answersure.com/privacy-policy" target="_blank"><span>Privacy</span></a>
    </div>
    <div class="social-links">
        <a href="https://www.facebook.com/AnswerSureLLC" target="_blank">
          <i class="fab fa-facebook-square"></i>
        </a>
        <a href="https://www.instagram.com/answersure_llc/" target="_blank">
          <i class="fab fa-instagram"></i></a>
    </div>
  </div>
</footer>
