import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '../../model/user/user';
import {Observable} from 'rxjs';
import {UpdateUserRequest} from '../../model/user/update-user-request';
import {AddUserRequest} from '../../model/user/add-user-request';
import {ChangePasswordRequest} from '../../model/user/change-password-request';

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private readonly http: HttpClient) {
  }

  getAllUsers(searchValue?: string): Observable<User[]> {
    // TODO add search value
    return this.http.get<User[]>('/users');
  }

  updateUser(user: UpdateUserRequest): Observable<UpdateUserRequest> {
    return this.http.put<UpdateUserRequest>('/users', user);
  }

  deleteUser(userID: number): Observable<void> {
    return this.http.delete<void>(`/users/${userID}`);
  }

  addUser(request: AddUserRequest): Observable<AddUserRequest> {
    return this.http.post<AddUserRequest>('/users', request);
  }

  forgotPassword(email: string): Observable<void> {
    const params = new HttpParams().set('email', email);
    return this.http.post<void>('/users/forgot-password', null, {params: params});
  }

  confirmPasswordChange(token: string): Observable<void> {
    return this.http.get<void>('/users/forgot-password/confirm?token=' + token);
  }

  changePassword(request: ChangePasswordRequest): Observable<void> {
    return this.http.post<void>('/users/change-password', request);
  }

}
