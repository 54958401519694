import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SendMessageRequest} from '../../model/message/send-message-request';
import {Observable} from 'rxjs';
import {Message} from '../../model/message/message';

@Injectable({providedIn: 'root'})
export class MessageService {

    constructor(private readonly http: HttpClient) {
    }

    sendMessage(request: SendMessageRequest): Observable<SendMessageRequest> {
        return this.http.post<SendMessageRequest>('/admin/messages', request);
    }

    getMessages(olderThanMessageId: number): Observable<Message[]> {
        const params = new HttpParams().set('olderThanMessageId', '' + (olderThanMessageId ? olderThanMessageId : ''));
        return this.http.get<Message[]>('/admin/messages/list', {params: params});
    }


}
