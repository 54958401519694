import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../core/services/auth.service';
import {User} from '../../../../model/user/user';

@Component({
  selector: 'app-invalid-subscription',
  templateUrl: './invalid-subscription.component.html',
  styleUrls: ['./invalid-subscription.component.scss']
})
export class InvalidSubscriptionComponent implements OnInit{

  principal: User;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.principal = this.authService.getPrincipal();
  }



}
