import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Survey} from '../../model/survey/survey';
import {SurveyReportItem} from '../../model/survey/survey-report-item';

@Injectable({providedIn: 'root'})
export class SurveyService {

  constructor(private http: HttpClient) {
  }

  createSurvey(survey: Survey): Observable<Survey> {
    return this.http.post<Survey>('/surveys', survey);
  }

  getSurvey(surveyId: number): Observable<Survey> {
    return this.http.get<Survey>('/surveys/' + surveyId);
  }

  getAllSurveys(): Observable<Survey[]> {
    return this.http.get<Survey[]>('/surveys');
  }

  getSurveyReports(): Observable<SurveyReportItem[]> {
    return this.http.get<SurveyReportItem[]>('/surveys/reports');
  }

  updateSurvey(survey: Survey): Observable<Survey> {
    return this.http.put<Survey>('/surveys', survey);
  }
}
