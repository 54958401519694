<div class="wrapper">
  <app-header></app-header>
  <div class="table-container page-width">
    <div class="align-flex-end top-table-container btn-actions-container">
      <button mat-button [disableRipple]="true" (click)="openModal(sendMessageTemplate)">Send Message</button>
    </div>
    <table mat-table [dataSource]="messagesTable" class="mess-table">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let message" class="content-ellipsis cursor-pointer">{{message.title}} </td>
      </ng-container>
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let message" class="content-ellipsis cursor-pointer">{{message.message}}</td>
      </ng-container>
      <ng-container matColumnDef="survey-title">
        <th mat-header-cell *matHeaderCellDef>Survey Title</th>
        <td mat-cell *matCellDef="let message" class="content-ellipsis cursor-pointer"> {{message.surveyTitle}} </td>
      </ng-container>
      <ng-container matColumnDef="survey">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Has Survey</th>
        <td mat-cell *matCellDef="let message" class="text-centered cursor-pointer"><i
          [ngClass]="message.surveyId ? 'fa-check' : 'fa-times'" class="fas dark-blue-color"></i></td>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef class="text-centered">Created On</th>
        <td mat-cell *matCellDef="let message" class="text-centered cursor-pointer"> {{message.createdOn | answersureDate : isTimeDisplayed}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDialogMessages(row)" class="table-row"></tr>
    </table>
  </div>
</div>
<app-footer></app-footer>

<ng-template #sendMessageTemplate>
  <form [formGroup]="sendessageForm" (ngSubmit)="sendMessage()" class="share-width">
    <div class="flex-column create-message-container">
      <h2 mat-dialog-title class="title">Send Message</h2>
      <div class="share-width">
        <mat-form-field class="message-input" appearance="outline">
          <mat-label>Title*</mat-label>
          <input type="text" matInput placeholder="Type a short title..." formControlName="title"/>
        </mat-form-field>
      </div>
      <div class="share-width">
        <mat-form-field class="message-input" appearance="outline">
          <mat-label>Message*</mat-label>
          <textarea cdkTextareaAutosize type="text" matInput placeholder="Type here..."
                    formControlName="message"></textarea>
        </mat-form-field>
      </div>
      <div class="share-width">
        <mat-form-field class="message-input" appearance="outline">
          <mat-label>Survey (Optional)</mat-label>
          <mat-select formControlName="surveyId" placeholder="Select Survey (Optional)">
            <mat-option [value]="undefined">No Survey</mat-option>
            <mat-option *ngFor="let survey of surveys" [value]="survey.id">{{survey.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="share-width" *ngIf="sendessageForm.controls.surveyId.value">
        <mat-form-field class="message-input" appearance="outline">
          <mat-label>Survey Title</mat-label>
          <input type="text" matInput placeholder="Type a survey title..." formControlName="surveyTitle"/>
        </mat-form-field>
      </div>
    </div>
    <div class="actions margin-bottom-15">
      <mat-dialog-actions align="end">
        <div class="action-btn-cancel">
          <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
        </div>
        <div class="action-btn">
          <button mat-button type="submit">Send</button>
        </div>
      </mat-dialog-actions>
    </div>
  </form>
</ng-template>
