import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MobileDevice} from '../../model/device/mobile-device';
import {AddRemoteDeviceRequest} from '../../model/device/add-remote-device-request';
import {MobileDeviceListItem} from '../../model/device/mobile-device-list-item';

@Injectable({providedIn: 'root'})
export class MobileService {

    mobileDeviceDetails: MobileDeviceListItem;

    constructor(private readonly http: HttpClient) {
    }

    registerDevice(token: string): Observable<void> {
        return this.http.get<void>('/mobile/register');
    }

    getMobileDevicesForUser(): Observable<MobileDevice[]> {
        return this.http.get<MobileDevice[]>('/mobile/devices/by-user');
    }

    getAllMobileDevices(): Observable<MobileDevice[]> {
        return this.http.get<MobileDevice[]>('/mobile/devices/all');
    }

    confirmMobileDevice(deviceId: string, token: string): Observable<void> {
        return this.http.post<void>('/mobile/confirm', {deviceId: deviceId, activationToken: token});
    }

    addRemoteDevice(deviceId: string, label: string): Observable<void> {
        const params = new HttpParams()
          .set('deviceId', deviceId)
          .set('label', label);
        return this.http.post<void>('/mobile/add-remote', null, {params: params});
    }

    updateRemoteDevice(request: AddRemoteDeviceRequest): Observable<MobileDevice> {
      return this.http.put<MobileDevice>('/mobile/update-remote', request);
    }

  /**
   * This is for admin only
   * @param deviceId
   */
    deleteDevice(deviceId: string): Observable<void> {
      const params = new HttpParams().set('deviceId', deviceId);
      return this.http.delete<void>('/mobile/devices', {params: params});
    }

    deleteRemoteDevice(deviceId: string): Observable<void> {
      const params = new HttpParams().set('deviceId', deviceId);
      return this.http.delete<void>('/mobile/remove-remote', {params: params});
    }

    getDeviceById(deviceId: string): Observable<MobileDeviceListItem> {
      return this.http.get<MobileDeviceListItem>('/mobile/devices/' + deviceId);
    }

}
