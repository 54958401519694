<h2 mat-dialog-title class="title">Edit Survey</h2>
<mat-dialog-content>
  <ng-container *ngIf="editSurveyForm">
    <form [formGroup]="editSurveyForm" (ngSubmit)="submitEditForm()" class="full-width" autocomplete="off">
      <div class="margin-bottom-25 flex">
        <mat-form-field class="input-field">
          <mat-label>Survey Name</mat-label>
          <input type="text" matInput formControlName="name" name="survey-name" autocomplete="off"/>
          <mat-error *ngIf="editSurveyForm.invalid">This field is required</mat-error>
        </mat-form-field>
        <div class="flex translate-check">
          <mat-checkbox disableRipple formControlName="hasTranslate" class="align-self-centered">Has Translate
          </mat-checkbox>
        </div>
      </div>
      <div class="question-background">
        <div formArrayName="questions" *ngFor="let question of questions.controls; let i = index;"
             class="question">
          <div class="q-number bold-text orange-color context-cursor">
            Question {{i + 1}}
          </div>
          <div [formGroupName]="i" class="set">
            <div class="flex-space-between">
              <mat-form-field class="input-field">
                <mat-label>Question {{i + 1}}</mat-label>
                <input type="text" matInput formControlName="question1" name="qn-name-one" autocomplete="off">
                <mat-error *ngIf="editSurveyForm.invalid">This field is required</mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="showTranslatedQuestion" class="input-field">
                <mat-label>Translated Question {{i + 1}}</mat-label>
                <input type="text" matInput formControlName="question2" name="qn-name-two" autocomplete="off">
                <mat-error *ngIf="editSurveyForm.invalid">This field is required</mat-error>
              </mat-form-field>
            </div>
            <div formArrayName="answers">
              <ng-container *ngFor="let answer of question.get('answers').controls; let answerIndex = index">
                <div [formGroupName]="answerIndex" class="flex-space-between">
                  <mat-form-field class="input-field">
                    <mat-label>Answer {{answerIndex + 1}}</mat-label>
                    <input type="text" matInput formControlName="answer1" name="ans-name-one" autocomplete="off">
                    <mat-error *ngIf="editSurveyForm.invalid">This field is required</mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf="showTranslatedQuestion" class="input-field">
                    <mat-label>Translated Answer  {{answerIndex + 1}}</mat-label>
                    <input type="text" matInput formControlName="answer2" name="ans-name-two" autocomplete="off">
                    <mat-error *ngIf="editSurveyForm.invalid">This field is required</mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
            <div class="checkboxes">
              <span class="first-check">
                <mat-checkbox disableRipple formControlName="hasMultipleChoice">user can choose <span
                  class="bold-text orange-color">multiple answers</span></mat-checkbox>
              </span>
              <span class="last-check">
                <mat-checkbox disableRipple formControlName="hasOtherAnswer">user can have <span
                  class="bold-text orange-color">Other</span>
              answer
              as option
               </mat-checkbox>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <mat-dialog-actions align="end">
          <div class="action-btn-cancel">
            <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
          </div>
          <div class="action-btn">
            <button mat-button type="submit">Save</button>
          </div>
        </mat-dialog-actions>
      </div>
    </form>
  </ng-container>
  <div *ngIf="loading" class="text-centered">
    Loading...
  </div>
</mat-dialog-content>

