import {Component, OnInit} from '@angular/core';
import {SubscriptionStatus} from '../../../model/subscription/subscription-status';
import {AuthService} from '../../../core/services/auth.service';
import {SubscriptionService} from '../../../core/services/subscription.service';
import {NotifService} from '../../../core/services/notif.service';
import {DialogService} from '../../../components/dialog/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {User} from '../../../model/user/user';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../../constants';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-view-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  readonly perCreditPrice = environment.perCreditPrice;
  readonly trialPeriod = environment.trialPeriod;

  principal: User;
  price: number;
  currentDate: Date;

  // TODO here we need to use forms
  updateForm: FormGroup = new FormGroup({
    quantity: new FormControl('', [Validators.required]),
  });
  newCreditsValue: number;

  isLoading = false;
  hasValidSubscription = false;
  nextPaymentBuffer = (24 * 60 * 60 * 1000) * 2; // 2 days

  constructor(private readonly authService: AuthService, private readonly subscriptionService: SubscriptionService,
              private readonly notifService: NotifService, private readonly dialogService: DialogService,
              private readonly modalService: MatDialog) {

  }

  ngOnInit(): void {
    this.principal = this.authService.getPrincipal();
    this.currentDate = new Date();
    this.price = Constants.PER_CREDIT_PRICE * this.principal.totalCredits;
    this.fetchSubscriptionInfo();
  }

  fetchSubscriptionInfo(): void {
    this.subscriptionService.getSubscription().subscribe(subscriptionInfo => {
      this.principal.subscriptionStatus = subscriptionInfo.subscriptionStatus;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.totalCredits = subscriptionInfo.totalCredits;
      this.principal.availableCredits = subscriptionInfo.availableCredits;
      this.hasValidSubscription = this.authService.hasValidSubscription();
      this.newCreditsValue = this.principal.totalCredits;
      this.authService.updatePrincipal(this.principal);
    });
  }

  cancelSubscription(): void {
    this.dialogService.openConfirmDialog('Cancel Subscription', 'Are you sure you want to cancel your subscription?',
      () => {
        this.subscriptionService.cancelSubscription().subscribe(user => {
          this.authService.updatePrincipal(user);
          this.principal = user;
          this.notifService.success('Your subscription has been canceled');
        });
      });
  }

  reactivateSubscription(): void {
    this.subscriptionService.reactivateSubscription().subscribe(user => {
      this.authService.updatePrincipal(user);
      this.principal = user;
      this.notifService.success('Your subscription has been reactivated with success');
    }, error => {
      this.notifService.error(error.error.message);
    });
  }

  updateSubscription(): void {
    console.log(this.newCreditsValue);
    if (this.newCreditsValue <= 0) {
      this.notifService.error('Please specify a valid amount of credits');
      return;
    }
    this.isLoading = true;
    this.subscriptionService.updateSubscription({credits: this.newCreditsValue}).subscribe(user => {
      this.authService.updatePrincipal(user);
      this.principal = user;
      this.modalService.closeAll();
      this.notifService.success('Subscription successfully updated!');
      this.isLoading = false;
    }, error => {
      this.notifService.error(error.error.message);
      this.isLoading = false;
    });
  }

  openModal(content: any): void {
    this.modalService.open(content, {
      panelClass: 'width-update',
    });
  }

  get SubscriptionStatus() {
    return SubscriptionStatus;
  }

}

