import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NotifService} from '../../core/services/notif.service';
import {AuthService} from '../../core/services/auth.service';
import {User} from '../../model/user/user';
import {MobileService} from '../../core/services/mobile.service';
import {MobileDevice} from '../../model/device/mobile-device';
import {DialogService} from '../../components/dialog/dialog.service';
import {SubscriptionService} from '../../core/services/subscription.service';
import {SubscriptionStatus} from '../../model/subscription/subscription-status';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})

export class DashboardPageComponent implements OnInit, OnDestroy {

  principal: User;
  devices: MobileDevice[];
  isUserDashboard: boolean;

  hasInvalidSubscription = false;

  constructor(private authService: AuthService, private router: Router, private notifService: NotifService,
              private mobileService: MobileService, private readonly dialogService: DialogService,
              private subscriptionService: SubscriptionService) {
  }

  ngOnInit(): void {
    this.isUserDashboard = true;
    this.principal = this.authService.getPrincipal();
    this.mobileService.getMobileDevicesForUser().subscribe(devices => {
      this.devices = devices;
    });
    this.fetchSubscriptionInfo();
  }

  fetchSubscriptionInfo(): void {
    this.subscriptionService.getSubscription().subscribe(subscriptionInfo => {
      this.principal.subscriptionStatus = subscriptionInfo.subscriptionStatus;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.subscriptionStartDate = subscriptionInfo.subscriptionStartDate;
      this.principal.totalCredits = subscriptionInfo.totalCredits;
      this.principal.availableCredits = subscriptionInfo.availableCredits;
      this.authService.updatePrincipal(this.principal);
      this.hasInvalidSubscription = !this.authService.hasValidSubscription();
    });
  }

  ngOnDestroy(): void {
    this.isUserDashboard = false;
  }


  logout(): void {
    this.authService.logout();
  }

  // confirmDevice(): void {
  //   this.mobileService.confirmMobileDevice(this.ownDeviceId, this.principal.activationToken).subscribe(success => {
  //     this.ngOnInit();
  //   }, error => {
  //     this.notifService.error(error.error.message);
  //   });
  // }


}

