<h2 mat-dialog-title class="title">Create Survey</h2>
<mat-dialog-content>
  <form [formGroup]="surveyForm" (ngSubmit)="submitCreateForm()" class="full-width" autocomplete="off">
    <div class="margin-bottom-25 flex">
      <mat-form-field class="input-field">
        <mat-label>Survey Name</mat-label>
        <input type="text" matInput formControlName="name" name="svy-name" autocomplete="off"/>
        <mat-error *ngIf="surveyForm.invalid">This field is required</mat-error>
      </mat-form-field>
      <div class="flex translate-check">
        <mat-checkbox disableRipple formControlName="hasTranslate" class="align-self-centered">Has Translate
        </mat-checkbox>
      </div>
    </div>
    <div class="question-background">
      <div formArrayName="questions" *ngFor="let question of questions.controls; let i = index;"
           class="question">
        <div *ngIf="i > 0" (click)="removeQuestion(i)" class="align-flex-end  blue-color close-icon"><i
          class="fas fa-times cursor-pointer"></i></div>
        <div [formGroupName]="i" class="set">
          <div class=" flex-space-between">
            <mat-form-field class="input-field">
              <mat-label>Question {{i + 1}}</mat-label>
              <input type="text" matInput formControlName="question1" name="qn-one" autocomplete="off"/>
              <mat-error *ngIf="surveyForm.invalid">This field is required</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showTranslatedQuestion" class="input-field">
              <mat-label>Translated Question {{i + 1}}</mat-label>
              <input type="text" matInput formControlName="question2" name="qn-two" autocomplete="off"/>
              <mat-error *ngIf="this.surveyForm.invalid">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div formArrayName="answers">
            <ng-container *ngFor="let answer of question.get('answers').controls; let answerIndex = index">
              <div [formGroupName]="answerIndex" class="flex-space-between">
                <mat-form-field class="input-field">
                  <mat-label>Answer {{answerIndex + 1}}</mat-label>
                  <input type="text" matInput formControlName="answer1" name="ans-one" autocomplete="off"/>
                  <mat-error *ngIf="surveyForm.invalid">This field is required</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="showTranslatedQuestion" class="input-field">
                  <mat-label>Translated Answer  {{answerIndex + 1}}</mat-label>
                  <input type="text" matInput formControlName="answer2" name="ans-two" autocomplete="off"/>
                  <mat-error *ngIf="surveyForm.invalid">This field is required</mat-error>
                </mat-form-field>
              </div>
              <div class="flex icons-container">
                  <span class="cursor-pointer icon-plus" (click)="addAnswer(question)"><i
                    class="fas fa-plus-circle"></i></span>
                <span class="cursor-pointer" *ngIf="answerIndex > 0" (click)="removeAnswer(question, answerIndex)"><i
                  class="fas fa-minus-circle"></i></span>
              </div>
            </ng-container>
          </div>
          <div>
            <div class="checkboxes">
             <span class="first-check">
                <mat-checkbox disableRipple formControlName="hasMultipleChoice">user can choose <span
                  class="bold-text orange-color">multiple answers</span></mat-checkbox>
             </span>
              <span class="last-check">
               <mat-checkbox disableRipple formControlName="hasOtherAnswer">user can have <span
                 class="bold-text orange-color">Other </span>answer
                as option
               </mat-checkbox>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="action-btn survey-action-btn">
        <button mat-button (click)="addQuestion()">+ Add question</button>
      </div>
    </div>
    <div class="actions">
      <mat-dialog-actions align="end">
        <div class="action-btn-cancel">
          <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
        </div>
        <div class="action-btn">
          <button mat-button type="submit">Save</button>
        </div>
      </mat-dialog-actions>
    </div>
  </form>
</mat-dialog-content>

